import React, { useEffect, useState } from 'react'

import { useFormik } from "formik";
import * as Yup from 'yup';

const Contact = () => {

    const [key, setKey] = useState(null)
    const [captcha, setCaptcha] = useState(null)
    const [msg, setMsg] = useState(null)
    const [sucess, setSucess] = useState(false)

    let captchaPreview = 'data:image/png;base64,' + captcha


    useEffect(() => {
        getCaptcha()
    }, [])


    function getCaptcha() {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: null
        };

        async function fetchCaptcha() {
            const response = await fetch('https://dennisschoene.com/website/api/captcha/', requestOptions)
            const data = await response.json();
            return data
        }
        fetchCaptcha().then(data => {
            setKey(data.captcha_key)
            setCaptcha(data.captcha_image)
        })
    }



    async function postContactForm(values, { resetForm }) {
        setSucess(false);

        const data = {
            name: values.name,
            email: values.email,
            message: `Name: ${values.name} \n Email: ${values.email} \n Message: ${values.message}`,
            captcha_value: values.captcha,
            captcha_key: key
        }

        const dataJsonString = JSON.stringify(data);

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: dataJsonString,
        };

        const response = await fetch('https://dennisschoene.com/website/api/contact-form/', requestOptions);

        if (!response.ok) {
            response.json().then(function (response) {
                if (response.msg.non_field_errors) {
                    setMsg('Captcha wrong')
                    getCaptcha()
                } else {
                    setMsg('Invalid form')
                }
            });
        } else {
            response.json().then(function (response) {
                setSucess(true);
                setMsg('Thanks for your mail!');
                resetForm();
            });
        }

    }




    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
            captcha: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(3, 'Too Short!')
                .max(30, 'Too Long!')
                .required('Required'),
            message: Yup.string()
                .min(5, 'Too Short!')
                .max(500, 'Too Long!')
                .required('Required'),
            email: Yup.string().email('Invalid email').required('Required'),
            captcha: Yup.string().length(5, 'Captcha is 5 digit long').required('Required'),
        }),

        onSubmit: (values, { resetForm }) => {

            postContactForm(values, { resetForm })

        }
    });







    return (
        <div className='contactContainer'>

            <div className="contactText">
                {msg ? <h2 style={{color: sucess ? '#687887' : '#db7070'}}>{msg}</h2> : null}
                <h2 className="">Don't be a stranger</h2>
                <h2 className="">
                    <span>just say </span>
                    <span className="hit">hello.</span>
                </h2>

                <p className="tiny padding">Feel free to get in touch with me. I am always open to discuss new projects, creative ideas or opportunities to explore new ways.</p>


                <div className="links">

                    <a href={'https://www.linkedin.com/in/dennis-schöne-7a1a531b5'} className="links-wrapper">
                        <svg viewBox="0 0 512 512" ><path fill='#222222' d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-256 406h-60v-210h60zm0-240h-60v-60h60zm210 240h-60v-120c0-16.539062-13.460938-30-30-30s-30 13.460938-30 30v120h-60v-210h60v11.308594c15.71875-4.886719 25.929688-11.308594 45-11.308594 40.691406.042969 75 36.546875 75 79.6875zm0 0" /></svg>
                    </a>
                    <a href={'https://github.com/dennisschoene'} className="links-wrapper">
                        <svg viewBox="0 0 512 512" ><path fill='#222222' d="m256 0c-140.609375 0-256 115.390625-256 256 0 119.988281 84.195312 228.984375 196 256v-84.695312c-11.078125 2.425781-21.273438 2.496093-32.550781-.828126-15.128907-4.464843-27.421875-14.542968-36.546875-29.910156-5.816406-9.8125-16.125-20.453125-26.878906-19.671875l-2.636719-29.882812c23.253906-1.992188 43.371093 14.167969 55.3125 34.230469 5.304687 8.921874 11.410156 14.152343 19.246093 16.464843 7.574219 2.230469 15.707032 1.160157 25.183594-2.1875 2.378906-18.972656 11.070313-26.074219 17.636719-36.074219v-.015624c-66.679687-9.945313-93.253906-45.320313-103.800781-73.242188-13.976563-37.074219-6.476563-83.390625 18.238281-112.660156.480469-.570313 1.347656-2.0625 1.011719-3.105469-11.332032-34.230469 2.476562-62.546875 2.984375-65.550781 13.078125 3.867187 15.203125-3.890625 56.808593 21.386718l7.191407 4.320313c3.007812 1.792969 2.0625.769531 5.070312.542969 17.371094-4.71875 35.683594-7.324219 53.726563-7.558594 18.179687.234375 36.375 2.839844 54.464844 7.75l2.328124.234375c-.203124-.03125.632813-.148437 2.035157-.984375 51.972656-31.480469 50.105469-21.191406 64.042969-25.722656.503906 3.007812 14.128906 31.785156 2.917968 65.582031-1.511718 4.65625 45.058594 47.300781 19.246094 115.753906-10.546875 27.933594-37.117188 63.308594-103.796875 73.253907v.015624c8.546875 13.027344 18.816406 19.957032 18.761719 46.832032v105.722656c111.808594-27.015625 196-136.011719 196-256 .003906-140.609375-115.386719-256-255.996094-256zm0 0" /></svg>
                    </a>
                    <a href={'https://www.instagram.com/dennisschoene/'} className="links-wrapper">
                        <svg viewBox="0 0 512 512"><path fill='#222222' d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-180 390c-74.441406 0-135-60.558594-135-135s60.558594-135 135-135 135 60.558594 135 135-60.558594 135-135 135zm150-240c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45 45 20.1875 45 45-20.1875 45-45 45zm0 0" /><path fill='#222222' d="m407 90c-8.277344 0-15 6.722656-15 15s6.722656 15 15 15 15-6.722656 15-15-6.722656-15-15-15zm0 0" /><path fill='#222222' d="m257 150c-57.890625 0-105 47.109375-105 105s47.109375 105 105 105 105-47.109375 105-105-47.109375-105-105-105zm0 0" /></svg>
                    </a>
                    <a href={'https://threema.id/W2C5JAJH'} className="links-wrapper">
                        <svg viewBox="0 0 209.851 211.724">
                            <path fill='#222222' d="M9.625,172.376c3.332-6.053,6.109-10.943,8.733-15.915 c2.017-3.823,3.915-7.714,5.733-11.637c3.074-6.633,1.958-12.301-3.209-17.743C7.573,113.066-0.92,96.818,0.08,76.873 c1.164-23.199,13.014-40.56,31.079-53.926C55.5,4.939,83.345-1.454,113.164,0.271c25.181,1.456,48.398,8.942,68.315,24.92 c14.631,11.737,25.001,26.358,27.655,45.364c3.053,21.858-3.764,40.856-18.447,56.935c-17.6,19.27-40.457,27.854-65.742,31.345 c-17.052,2.353-33.883-0.396-50.819-1.234c-6.667-0.33-13.625,0.597-20.115,2.258c-12.56,3.217-24.875,7.384-37.31,11.095 C14.83,171.511,12.86,171.737,9.625,172.376z M35.298,151.684c0.81,0.146,1.154,0.315,1.434,0.242 c1.606-0.421,3.245-0.776,4.788-1.376c14.123-5.485,28.531-6.461,43.646-4.972c13.661,1.347,27.686,1.65,41.305,0.17 c23.252-2.526,43.52-12.176,58.48-30.909c15.321-19.186,16.479-43.907,3.043-64.168c-5.969-9.001-13.959-16.053-23.168-21.686 c-21.217-12.978-44.432-17.448-68.961-15.758c-24.295,1.676-46.206,9.531-64.31,26.335c-20.85,19.352-25.291,46.954-8.56,70.194 c2.521,3.501,5.481,6.69,8.296,9.975c6.143,7.165,8.8,15.142,5.952,24.495C36.521,146.6,35.985,149.031,35.298,151.684z" /><path fill='#222222' d="M160.457,181.682c8.546-0.116,15.373,6.522,15.367,14.943 c-0.004,8.113-6.719,14.935-14.854,15.09c-8.316,0.157-15.342-6.732-15.373-15.076C145.568,188.44,152.17,181.794,160.457,181.682z" /><path fill='#222222' d="M64.5,196.699c-0.058,8.544-6.864,15.226-15.301,15.021 c-8.189-0.199-14.759-6.968-14.73-15.173c0.031-8.488,6.804-15.045,15.385-14.893C58.257,181.803,64.558,188.275,64.5,196.699z" /><path fill='#222222' d="M119.969,196.671c-0.045,8.373-7.035,15.214-15.374,15.044 c-8.261-0.168-14.733-6.814-14.73-15.125c0.003-8.55,6.688-15.064,15.306-14.917C113.471,181.815,120.012,188.446,119.969,196.671z" />
                            <path fill='#222222' d="M131.059,76.618c5.577,0.521,6.446,4.105,6.324,8.824 c-0.254,9.817-0.075,19.646-0.15,29.469c-0.041,5.256-1.609,6.845-6.849,6.859c-17.482,0.047-34.965-0.017-52.448,0.057 c-4.54,0.019-6.768-1.843-6.736-6.537c0.07-10.655-0.146-21.314,0.064-31.966c0.037-1.87,1.205-4.937,2.502-5.337 c3.979-1.228,3.587-3.932,3.617-6.892c0.048-4.814-0.166-9.694,0.527-14.43c2.039-13.926,14.238-24.401,27.125-23.727 c14.281,0.749,25.564,12.524,25.998,27.28C131.19,65.531,131.059,70.85,131.059,76.618z M120.346,76.723 c0-6.325,0.23-12.134-0.058-17.917c-0.387-7.753-6.661-13.989-14.357-14.756c-7.657-0.764-15.854,4.025-17.03,11.479 c-1.083,6.866-0.23,14.038-0.23,21.194C99.264,76.723,109.508,76.723,120.346,76.723z" />
                        </svg>
                    </a>

                </div>


            </div>

            <div className="contactForm">

                <form onSubmit={formik.handleSubmit}>
                    <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        className={`form-input${formik.touched.name && formik.errors.name ? "-invalid" : ""
                            }`}
                    />
                    <input
                        name="email"
                        type="text"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        className={`form-input${formik.touched.email && formik.errors.email ? "-invalid" : ""
                            }`}
                    />
                    <textarea
                        name="message"
                        placeholder="Message"
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        className={`form-input${formik.touched.message && formik.errors.message ? "-invalid" : ""
                            }`}
                        style={{ height: '200px', resize: 'none' }}
                    />

                    <div className="captcha-wrapper">
                        {captcha ?
                            <>
                                <div
                                    style={{
                                        width: '20px',
                                        height: '50%',
                                        margin: '1em 0',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    onClick={() => getCaptcha()}>
                                    <h2>⟳</h2>
                                </div>
                                <img src={captchaPreview} alt="CaptchaPreview" />
                            </>
                            :
                            <p>...loading</p>}



                        <input
                            name="captcha"
                            placeholder="Captcha"
                            onChange={formik.handleChange}
                            value={formik.values.captcha}
                            className={`form-input${formik.touched.captcha && formik.errors.captcha ? "-invalid" : ""
                                }`}
                        />
                    </div>

                    <button
                        type="submit"
                        className={`btnSubmit${!(formik.isValid && formik.dirty) ? "inactive" : ""
                            }`}
                    >Submit</button>
                </form>


            </div>
        </div >
    )
}


export default Contact





