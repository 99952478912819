import React, { useEffect, useState } from 'react'

import gsap from 'gsap/gsap-core';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Upcoming from 'components/Utils/Viz/Upcoming';
import Device from 'components/Utils/Viz/Device';

const StoAppFirstSection = () => {


    const [screen, setScreen] = useState("appImages/stoApp_Start.webp")



    useEffect(() => {

        //////////////////////////////////////
        // PHONE ANIMATION 


        let onUpdate = ({progress}) => {
            if (progress < .07) { setScreen("appImages/stoApp_Start.webp") } else
            if (progress < .15) { setScreen("appImages/stoApp_Registration.webp") } else
            if (progress < .3) { setScreen("appImages/stoApp_Settings.webp") } else
            if (progress < .5) { setScreen("appImages/stoApp_Warehouse.webp") } else
            if (progress < .75) { setScreen("appImages/stoApp_Home.webp") } else
            if (progress < 1) { setScreen("appImages/stoApp_Storage.webp") } else
            setScreen("appImages/stoApp_Start.webp")
          }

        ScrollTrigger.matchMedia({

            // FOR DESKTOP SETUP

            "(min-width: 769px)": function () {
                const phoneTL = gsap.timeline({
                    id: "phoneTL",
                    scrollTrigger: {
                        trigger: '.demoWrapper',
                        start: 'top 30%',
                        end: 'bottom 50%',
                        toggleActions: 'play none none reverse',
                        pin: '.phonedummy',
                        onUpdate,
                        // markers: true,
                        pinSpacing: false
                    },
                });

                phoneTL.from('.phonedummy', { x: -20, opacity: 0, duration: .2 })
            },

            // FOR MOBILE SETUP

            "(max-width: 768px)": function () {
                const phoneTL = gsap.timeline({
                    id: "phoneTL",
                    scrollTrigger: {
                        trigger: '.demoWrapper',
                        start: 'top 40%',
                        end: 'bottom 30%',
                        toggleActions: 'play none none reverse',
                        onUpdate,
                        pin: '.phonedummy',
                        // markers: true,
                        pinSpacing: false
                    },
                });

                phoneTL.from('.phonedummy', { x: -20, opacity: 0, duration: .2 })
            }

        })






        ////////////////////////////////////
        // TEXT WRAPPER FROM RIGHT 


        gsap.to('.descriptionWrapper', {
            id: "phoneID2",
            scrollTrigger: {
                trigger: '.first_section',
                start: 'top 75%',
                end: 'bottom 20%',
                toggleActions: 'play reverse restart reverse',
            },
            duration: .2,
            x: '-10',
            opacity: 1,
            stagger: {
                each: .1,
            }
        })

        let targets = document.querySelectorAll(".architecture p")

        let targets3 = document.querySelectorAll(".demoWrapper div")




        const scrolltl = gsap.timeline({
            id: "scrollID",
            scrollTrigger: {
                trigger: ".first_section",
                start: "top 55%",
                end: "bottom 60%",
                // markers: true,
                toggleActions: 'play none none reverse',
            }
        });





        const scrolltl2 = gsap.timeline({
            id: "scrollID2",
            scrollTrigger: {
                trigger: ".demoWrapper",
                start: "top center",
                end: "bottom bottom",
                toggleActions: 'play none none reverse',
                scrub: true
            }
        });

        scrolltl
            .from(targets, { y: 10, opacity: 0, stagger: .2, duration: .2 })
            .from(".architectureImg", { x: -20, opacity: 0, duration: .4 })


        scrolltl2.from(targets3, { y: 20, opacity: 0, stagger: .2, duration: .1 })



        // KILL ALL SCROLLTRIGGERS    

        return () => {
            ScrollTrigger.getById("phoneTL").kill();
            ScrollTrigger.getById("phoneID2").kill();
            ScrollTrigger.getById("scrollID").kill();
            ScrollTrigger.getById("scrollID2").kill();
        }

    }, []);

    return (
        <div className="first_section">

            <div className="phonedummy">
                <Device />
                <div className="phoneshowchase">
                    {/* <img src="splash.png" alt=""/> */}
                    <img src={screen} alt="" />
                </div>
            </div>

            <div className="architectureImg">
                <img src="" alt="" />
            </div>

            <div className="descriptionWrapper">
                <div className="architecture">
                    <h2 className="spread">::Application architecture</h2>
                    <p className="tiny padding">CRUD Application using RESTful API</p>
                    <p className="tiny padding">The back-end server uses Django with Rest Framework to export REST APIs and interacts with a PostgreSQL database using Django Model.</p>
                    <p className="tiny padding">Front-end is set up with React-Native, able to compile to Android apk as well as Apple iOS.</p>
                </div>
            </div>




            <div className="demoWrapper">

                <div className="demoWrapperHeadline">
                    <h2 className="spread">::Feature set</h2>
                </div>

                <div className="demoDescrpWrapper">
                    <h2 className="spread hit">User Model</h2>
                    <p className="spread padding">AUTHENTICATION</p>
                    <p className="bulletpoint tiny padding">Token-based authentication using Django REST Framework</p>
                    <p className="bulletpoint tiny padding">Refresh Token in Authentication every 30 min</p>
                    <p className="bulletpoint tiny padding">Store session Token in Local Storage</p>
                    <p className="padding spread">SETTINGS</p>
                    <p className="bulletpoint tiny padding">Visibility / Listing option</p>
                    <p className="bulletpoint tiny padding">Camera usage setting</p>

                    <div className="upcomingWrapper">
                        <Upcoming />
                        <h4 className="spread">Upcoming</h4>
                        <p className="tiny bulletpoint">Email verification</p>
                        <p className="tiny bulletpoint">Password recovery</p>
                        <p className="tiny bulletpoint">Implementation of JWT</p>
                        <p className="tiny bulletpoint">Encrypted Token Pattern</p>
                    </div>
                </div>

                <div className="demoDescrpWrapper">
                    <h2 className="spread hit">Group Model</h2>
                    <p className="padding spread">WAREHOUSE</p>
                    <p className="bulletpoint tiny padding">2-level authorization concept</p>
                    <p className="bulletpoint tiny padding">Primary Identifier option</p>
                    <p className="bulletpoint tiny padding">Costum Icon selection</p>
                    <div className="upcomingWrapper">
                        <Upcoming />
                        <h4 className="spread">Upcoming</h4>
                        <p className="tiny bulletpoint">Real time Chat-Module via WebSocket protocol</p>
                    </div>
                </div>

                <div className="demoDescrpWrapper">
                    <h2 className="spread hit">Entity Model</h2>
                    <div>
                        <p className="padding spread">STORAGE</p>
                        <p className="bulletpoint tiny padding">Indication of stock condition</p>
                        <p className="bulletpoint tiny padding">Filter by stock condition</p>
                        <p className="bulletpoint tiny padding">Storage Condition</p>
                    </div>
                    <div>
                        <p className="padding spread">ITEM</p>
                        <p className="bulletpoint tiny padding">Stock condition and storage period</p>
                        <p className="bulletpoint tiny padding">3-level alert system</p>
                        <p className="bulletpoint tiny padding">Acknowledgement of alarms and muting</p>
                        <p className="bulletpoint tiny padding">Custom database of all items</p>
                        <p className="bulletpoint tiny padding">Search by EAN code</p>
                    </div>
                    <div className="upcomingWrapper">
                        <Upcoming />
                        <h4 className="spread">Upcoming</h4>
                        <p className="tiny bulletpoint">Recommendation system according on storage condition</p>
                        <p className="tiny bulletpoint">Valuation of the stored goods</p>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default StoAppFirstSection