
 // Get location of route from url (use in header, HOC)
 
 const routes = [
    {
      key: 'Algae Monitor',
      exact: true,
      path: '/algae',
    },
    {
      key: 'Contact me',
      exact: true,
      path: '/Contact',
    },
    {
      key: 'Storage App',
      exact: true,
      path: '/stoapp',
    },
    ]
  
  
    export default routes