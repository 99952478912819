import React from "react";
import "./styles/App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import Header from "./components/Header";
import Home from "./components/Home";
import Algae from "./components/Algae";
import Contact from "./components/Contact";
import StorageApp from "./components/StorageApp";



function App() {
  return (
    <Router>
      <div className='App'>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/storageapp' component={StorageApp} />
            <Route exact path='/algae' component={Algae} />
            <Route exact path='/contact' component={Contact} />
          </Switch>
        </div>
    </Router>
  );
}
export default App;
