import React, { useRef, useState, Suspense, useMemo } from "react";
import { Canvas, useFrame } from "react-three-fiber";
import { DepthOfField, EffectComposer, SSAO, Vignette } from '@react-three/postprocessing'
import { a, useSpring } from "@react-spring/three"
import * as THREE from "three";


function Swarm({ count, mouse, active }) {
    const mesh = useRef()

    const [dummy] = useState(() => new THREE.Object3D())

    const { color, rotation, scale } = useSpring({
        color: active ? '#adb4c0' : 'white',
        rotation: active ? [THREE.Math.degToRad(180), 0, THREE.Math.degToRad(90)] : [0, 0, 0],
        scale: active ? [1.5, 1.5, 1.5] : [.8, .8, .8],
        config: { mass: 1, tension: 120, friction: 14 },
    })


    const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
            const t = Math.random() * 100
            const factor = 20 + Math.random() * 100
            const speed = 0.1 + Math.random() / 200
            const xFactor = -20 + Math.random() * 10
            const yFactor = -20 + Math.random() * 30
            const zFactor = -20 + Math.random() * 40
            temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
        }
        return temp
    }, [count])

    useFrame((state, active) => {

        particles.forEach((particle, i, kof) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle
            t = particle.t += speed / 30
            const a = Math.cos(t) + Math.sin(t * 1) / 10
            const b = Math.sin(t) + Math.cos(t * 2) / 10
            const s = Math.max(1.5, Math.sin(t) * 4)
            particle.mx += (state.mouse.x * state.viewport.width - particle.mx) * 0.02
            particle.my += (state.mouse.y * state.viewport.height - particle.my) * 0.02
            dummy.position.set(
                (particle.mx / 100) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
                (particle.my / 100) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
                (particle.my / 100) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
            )
            dummy.scale.set(s, s, s)
            dummy.updateMatrix()
            mesh.current.setMatrixAt(i, dummy.matrix)
        })
        mesh.current.instanceMatrix.needsUpdate = true
    })

    // MESH CAP MATERIAL
    // const [matcap, url] = useMatcapTexture(
    //     271, // index of the matcap texture https://github.com/emmelleppi/matcaps/blob/master/matcap-list.json
    //     128 // size of the texture ( 64, 128, 256, 512, 1024 )
    // )
    // You can also use the exact name of the matcap texture, like so:
    // const [matcap] = useMatcapTexture('3E2335_D36A1B_8E4A2E_2842A5')
    // Use the url to download the texture when you are ready for production!

    // const [normalMap, url] = useNormalTexture(
    //     21, // index of the normal texture - https://github.com/emmelleppi/normal-maps/blob/master/normals.json
    //     // second argument is texture attributes
    //     {
    //       offset: [0, 0],
    //       repeat: [2,2],
    //       anisotropy: 3
    //     }
    //   )




    return (
        <a.instancedMesh
            ref={mesh}
            args={[null, null, count]}
            castShadow
            receiveShadow
            rotation={rotation}
            scale={scale}
        >
            <sphereBufferGeometry args={[1, 32, 32]} />
            <a.meshPhongMaterial
                color={color}
            />


            {/* <meshMatcapMaterial matcap={matcap} color='#bbc2cf'/> */}
            {/* <meshStandardMaterial normalMap={normalMap} normalScale={[4, 2]}/> */}



        </a.instancedMesh>
    )
}

function ParticleLight(props, active) {

    const mesh = useRef()

    const { int } = useSpring({
        int: active ? 10 : 1,
    })



    useFrame((state) => {
        const timer = state.clock.getElapsedTime()

        mesh.current.position.x = Math.sin(timer * .5) * 30;
        mesh.current.position.y = Math.cos(timer * .5) * 30 + 80;
        mesh.current.position.z = Math.cos(timer * .5) * 40
    });

    return (
        <mesh ref={mesh} >
            <a.pointLight intensity={int} color="red" />
        </mesh>
    )

}



// RIG FOR CONTROLLING THE VIEWPORT

// function Rig() {
//     return useFrame((state) => {
//       state.camera.position.x = THREE.MathUtils.lerp(state.camera.position.x, 2 + state.mouse.x / 2, 0.075)
//       state.camera.position.y = THREE.MathUtils.lerp(state.camera.position.y, 1.5 + state.mouse.y / 4, 0.075)
//     })
//   }



// function Octa(active) {
//     const octaRef = useRef()

//     const { nodes, materials } = useGLTF('TestShape.glb', true)

//     const { color, pos, ...props } = useSpring({
//         color: active ? 'hotpink' : 'white',
//         pos: active ? [0, 0, 2] : [0, 0, 0],
//         'material-opacity': active ? 0.6 : 0.25,
//         scale: active ? [15, 15, 15] : [1, 1, 1],
//         rotation: active ? [THREE.Math.degToRad(180), 0, THREE.Math.degToRad(45)] : [0, 0, 0],
//         config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 }
//     })

//     // useFrame(state => {
//     //     const time = state.clock.getElapsedTime();
//     //     octaRef.current.rotation.y += 0.005;
//     //     // octaRef.current.rotation.x = octaRef.current.rotation.x += 0.0005;
//     // })

//     return (
//         <mesh
//             {...props}
//             geometry={nodes.Shape.geometry}
//             ref={octaRef}
//         >
//             <a.meshPhongMaterial
//                 color={color}
//             />
//         </mesh>
//     )
// }



export default function Model(props) {



    return (
        <Canvas
            shadowMap
            gl={{ alpha: false, antialias: false }}
            camera={{ fov: 60, position: [0, 0, 60], near: 10, far: 250 }}
            onCreated={(state) => {
                state.gl.setClearColor('#f0f0f0')
            }}>


            <Suspense fallback={null}>

                <a.ambientLight intensity={1.2} />
                <pointLight position={[100, 100, 100]} intensity={2} castShadow />
                <pointLight position={[-100, -100, -100]} intensity={5} color="white" />

                <fog attach="fog" args={["#f0f0f0", 40, 80]} />

                <ParticleLight position={[0, 0, 0]} active={props.active} />
                <Swarm count={50} active={props.active} />
=

                <EffectComposer multisampling={2}>
                    <SSAO samples={25} radius={15} intensity={20} luminanceInfluence={.1} color="#222222" />
                    <Vignette eskil={false} offset={0.2} darkness={0.4}/>
                    <DepthOfField focusDistance={.16} focalLength={0.2} bokehScale={5} height={400} />
                </EffectComposer>

            </Suspense>

        </Canvas>
    )
}

