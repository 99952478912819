import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import Hamburger from "./Utils/Hamburger"
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useLocation } from "react-router-dom"
import { matchPath } from "react-router"
import routes from "./Utils/Breadcrumb"

gsap.registerPlugin(ScrollTrigger);


const Header = ({ history }) => {

  // State for disbaled button
  const [disabled, setDisabled] = useState(false)

  const [state, setState] = useState({
    initial: false,
    clicked: null,
    //Set displayed name 'Menu' to 'Close' if clicked
  })


  // Get route from Utils/Breadcrumb
  const location = useLocation();

  const currentRoute = routes.find(
    route => matchPath(location.pathname, route)
  )


  useEffect(() => {
    //Listening for page changes.
    history.listen(() => {
      setState({ clicked: false });
    });
  }, [history]);


  useEffect(() => {
    /* ALL THE GSAP CODE HERE */


    gsap.to(".header", {
      scrollTrigger: {
        id: "headerId",
        trigger: "header",
        start: 'top -30',
        end: 99999,
        toggleClass: { className: 'header--scrolled', targets: '.header' }
      }
    })

    return () => {
      // In this function kill all your GSAP instances
      // that could be affecting other areas of your code
      // our throwing errors

      ScrollTrigger.getById("headerId").kill();
    };
  }, []);

  const handleMenu = () => {
    disbaleMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true
      })
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked
      })
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked
      })
    }
  }


  // Determine if menu button should be disabled; against spamming

  const disbaleMenu = () => {
    setDisabled(!state.disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1200)
  }



  return (
    <div className="header">
      <div className="header-namespace">
        <h5 className="first-name title">DENNIS</h5>
        <h5 className="last-name title">SCHOENE</h5>
        {currentRoute ? <p className="header-breadcrumb"> | {currentRoute.key}</p> : null}
      </div>
      <div className="inner-header">
        <button
          disabled={disabled}
          onClick={handleMenu}
        >
          <div className="button-wrapper">
            {/* {state.menuName} */}
            <div className={state.clicked ? "burger--open" : "burger"} />
          </div>
        </button>
      </div>
      <Hamburger state={state} />
    </div>
  )
};

export default withRouter(Header);