import React from 'react'
import { Link } from 'react-router-dom'

const StoAppFooter = () => {

    return (
        <div className="stoAppFooter">
            <div className="textWrapper">
                <h2>Thanks for reading!</h2>
                <p className="padding tiny">
                    <span>If you like the concept - please </span>
                    <span className="bold">
                        <Link
                            to='/contact'>let me know</Link>
                    </span>
                </p>
            </div>
            <div className="imgWrapper">
                <img src="top.webp" alt="" />
            </div>
        </div>
    )
}

export default StoAppFooter