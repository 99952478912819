import React from 'react'

const Device = () => {

    return (
        <svg viewBox="0 0 841.89 595.28">
            <path fill="#010202" d="M511.595,194.633v-65.888c0-21.064-17.069-38.134-38.134-38.134H333.869
	c-21.056,0-38.125,17.069-38.125,38.134v20.536h-2.409v17.174h2.409v11.715h-2.409v17.165h2.409V490.2
	c0,21.056,17.07,38.134,38.125,38.134h139.592c21.064,0,38.134-17.078,38.134-38.134V241.527h2.399v-46.894H511.595z
	 M503.478,489.935c0,15.728-12.75,28.478-28.478,28.478H334.739c-15.728,0-28.477-12.75-28.477-28.478V129.01
	c0-15.728,12.749-28.477,28.477-28.477h17.019c-0.661,1.622-1.002,3.357-1.002,5.108c0,7.472,6.057,13.53,13.529,13.53h79.968
	c1.752,0,3.487-0.34,5.108-1.002c6.919-2.822,10.24-10.718,7.418-17.637H475c15.728,0,28.478,12.75,28.478,28.477V489.935z"/>
            <path fill="#F0F0F0" d="M370.632,110.855h49.506c1.923,0,3.482-1.277,3.482-2.853c0-1.575-1.56-2.852-3.482-2.852h-49.506
	c-1.923,0-3.483,1.277-3.483,2.852C367.149,109.578,368.709,110.855,370.632,110.855z"/>
            <circle fill="#F0F0F0" cx="437.546" cy="108.131" r="2.69" />
            <path fill="#F0F0F0" d="M169.165,9v571h469V9H169.165z M513.994,241.527h-2.398V490.2c0,21.056-17.07,38.134-38.135,38.134H333.87
	c-21.056,0-38.125-17.078-38.125-38.134V195.334h-2.409v-17.165h2.409v-11.715h-2.409v-17.174h2.409v-20.536
	c0-21.064,17.069-38.134,38.125-38.134h139.591c21.064,0,38.135,17.069,38.135,38.134v65.888h2.398V241.527z"/>
        </svg>

    )
}

export default Device