import React from 'react'

import "./DennisViz.scss";

const Dennis = () => {

	return (
		<div className='dennis-wrapper'>
			<svg viewBox="0 0 328 932" >
				<g id="Ebene_12" display="none">
					<g display="inline">
						<path fill="#C7CCD1" d="M-33,61c-20,68-18,136-48,200c-28,62-88,122-90,192c-2,80,70,138,124,184c80,64,302,250,394,116
			c44-62,38-156,54-230c16-76,60-140,84-214C528.979,171.788,397.076,1.407,241-45C90.78-89.667-13-7-33,61z"/>
					</g>
				</g>
				<g id="Ebene_11">
				</g>
				<g id="Kopf">
					<path fill="#e7ad81" d="M219.1,159.8c-0.28,5.76-3.323,13.197-7.683,15.867c-4.53,2.77-10.074,6.376-15.083,8.167
		c-7.13,2.54-16.514,3.784-24.083,4.083c-4.87,0.19-10.973-0.287-15.833-0.667c-4.62-0.36-13.83-2.77-15.479-8
		c-1.4-4.44,2.964-10.872,3.604-15.292c0.49-3.38,0.473-12.159,0.458-12.25c-0.792-5.125-5.37-7.378-9.67-10.039
		c-2.44-1.52-4.67-1.88-8.64-3.17c-2.83-0.92-7.63,0.25-12.07-4.19c-6.31-6.31,1.82-10.62,1.88-14.43c0.04-2.42-3.46-3.75-3.19-6.32
		c0.24-2.27,5.68-3.5,3.63-4.68c-2.94-1.69-4.07-1.97-4.56-4.57c-0.51-2.62,2.14-8.69-0.6-9.51c-4.33-1.29-6.78-2.38-7.4-7
		c-0.39-2.84,4.7-7.61,6.33-9.37c3.44-3.74,7.71-6.12,5.73-9.7c-1.27-2.3-2.44-3.3-2.11-6.05c0.35-2.86,2.89-6.37,4.38-8.68
		c3.05-4.76,4.51-9.92,8-14.56c3.63-4.83,7.44-8.44,12.62-11.44h-0.83c0.94-1,1.9-1.16,2.87-1.61c9.42-4.3,19.85-3.2,28.74,1.79
		c8.7,4.88,17.75,10.57,24.48,18c7.27,8.03,17.19,18.3,20.18,28.94c3.27,11.67-2.31,23.4-8.11,33.22
		c-5.84,9.9-7.76,18.52-3.71,30.58c2.78,3.41,3.44,8.14,6.9,10.96C213.27,152.66,219.37,154.38,219.1,159.8z"/>
				</g>
				<g id="Haare">
					<path fill="#9B7A59" d="M131.688,33.5c-3.481,1.942,2.171-7.57,5.75-10.375c3.019-2.365,3.044-0.213,6.154-2.313
		c3.094-2.09,4.875-5.929,8.984-6.452c3.976-0.506,7.677,2.462,11.665,1.586c9.009-1.978,16.188-1.645,24.064,2.575
		c5.944,3.185,15.033,4.942,19.592,9.994c4.896,5.425,8.664,11.816,13.004,17.623c0.459,0.614,0.775,1.425,1.131,2.105
		c7.842-9.992,16.803-14.862,27.967-7.617c8.493,5.511,12.786,19.345,6.125,27.875c-7.125,9.125-15.519,6.14-21.25,6.25
		c4.047,3.551,0.395,16.59,9.486,16.446c1.801-0.029,3.75-1.498,5.447-2.049c0.771,2.777-4.529,3.048-2.555,5.584
		c1.75,2.247,5.377-0.22,5.974-2.177c5.65,3.385-2.381,11.355-7.395,9.475c-1.966-0.737-3.676-5.438-5.035-5.44
		c-1.339-0.002-1.25,2.521-2.533,2.775c-1.758,0.347-2.377-1.662-3.076-2.659c-0.349-0.499,0.133-1.338-0.375-1.852
		c-0.57-0.576-1.492-0.195-2.066-0.598c-1.524-1.067-2.38-1.343-2.72-3.501c1.908,9.361-1.778,16.621-7.903,22.371
		c-5.096,4.784-8.333,17.009-16.438,16.813c-7.737-0.188-12.821-14.595-10.938-20.875c1.159-3.864,3.146-7.567,4.438-11.313
		c1.984-5.753,3.575-9.453,3.438-13.438c-0.125-3.625,1.459-12.875-9.417-14.021c-3.306-0.348-7.037-0.801-13.083,4.167
		c-3.112,2.557-3.357,9.722-6.083,12.708c-2.193,2.403-3.696,3.869-5.446,1.202c-2.203-3.357-1.384-11.486-0.601-15.106
		c2.246-10.375-4.9-13.392-10.113-20.877c-5.396-7.747,5.292-14.264,0.43-20.885c-4.896-6.667-16.635-4.67-21.938-1.125
		C132.667,32.854,132.721,32.923,131.688,33.5z"/>
				</g>
				<g id="Bart">
					<path opacity="0.15" fill="#9B7A59" d="M114.083,134.958c-5.333-4.396-1.918-13.767,2.506-11.333c5,2.75,10.092,4.895,15.535,4.375
		c6.716-0.642,20.165-3.8,27.125-6.625c11.5-4.667,10.316-14.122,10.417-15.536c0.18-2.546-3.109-18.182,0.333-20.839
		c5.008-3.866,3.859,9.856,3.988,13.25c0.237,6.241,1.507,12.747-2.613,19.5c-3.554,5.824-11.507,8.695-17.438,11.188
		c-5.853,2.46-9.915,4.61-15.938,7.188C136.324,136.842,123.382,142.623,114.083,134.958z"/>
					<path opacity="0.2" fill="#9B7A59" d="M113.938,115.484c-1.035,1.065,0.53,1.384,1.015,2.534c0.716,1.698,1.293,0.821,2.444-0.141
		c0.882-0.737,1.714-0.252,2.115-1.454c0.537-1.608-1.853-2.35-3.082-1.816c-1.198,0.52-0.574,0.921-2.055,0.768L113.938,115.484z"
					/>
				</g>
				<g id="hose" className='hose'>
					<path fill="#3c3f44" d="M152.25,596.25c-0.25,3.58-2.17,10.1-1.5,13.5c0.78,3.97,2.7,8.12,2.7,11.91c-0.01,3.29-3.1,6.22-2.85,9.74
		c0.24,3.319,1.28,6.779,1.65,10.43c0.17,1.729,1.09,3.87,0.95,5.53c-0.16,1.89-1.88,2.989-1.62,5.08
		c0.15,1.26,1.98,2.51,2.16,4.229c0.19,1.7-1.22,3.37-1,5.15c0.48,3.939,3.82,7.12,4.03,11.399c0.11,2.17,0,4.521-0.23,6.92
		c0.61,2.7,1.04,5.551,1.21,8.61c1.13,20.23-2.69,34.79-1.72,55.03c0.19,4,0.26,7.66-0.04,11.149c0.24,1.03,0.55,2.101,0.95,3.261
		c1.43,4.17-1.1,6.699-2.36,10.52c-1.28,3.87,1.16,6.39,2.01,10.021c0.8,3.42-0.15,7.079-2.21,9.909
		c-2.38,3.271-6.11,4.261-9.94,4.851c-4.65,0.71-7.35-0.471-11.54-2.12c-3.43-1.351-8.59,1.26-11.42-0.84
		c-1.68-1.25-2.14-3.87-2.85-6.3c-3.97-2.25-6.77-6.25-7.38-11.61c-2.37-20.79-4.22-64.2-9.58-85.12
		c-2.36-9.19-3.25-15.45-4.03-21.9c-2.07-3.58-4.38-7.189-5.29-10.96c-0.69-2.899,0.587-11.827-0.589-11.711
		c-6.198-8.866-8.081-27.669-9.561-34.079c-4.16-18.01-2.49-26.1-5.62-44.38c-1.6-9.33-5.41-15.689-7.82-24.22
		c-1.99-7.01,2.62-10-0.08-27.53c1.7-13.05,2.07-14.489,4.59-21.939c1.47-4.32,1.59-6.891,2.11-12.261
		c0.39-4.14,1.84-6.729,2.12-10.52l2-2c-0.8-4.31-1.95-9.33-1.36-13.73c3.27-24.06,38.02-10.5,48.87,0.271
		c15.16,15.06,8.75,38.02,14.04,56.91c5.91,21.1,22.17,3.01,19.7,25.8C159.47,547.05,156.52,570.66,152.25,596.25z"/>
					<path fill="#3c3f44" d="M254.58,545.12c-5.35,10.14-2.17,19.149-3.78,29.81c-1.37,9.021-8.491,15.63-8.551,24.57
		c-0.06,8.79,4.741,9.59,3.661,19.02c-0.91,7.94-1.173,12.641,0.757,20.48c1.64,1.64,2.24,16.857,2.5,20.667
		c0.69,10.15,3.663,8.923,2.833,19.083c-0.87,10.73,3.07,26.6,1.5,37.25c-1.06,7.21-4.805,17.035-6.375,24.625
		c-0.21,4.35-0.455,14.415-0.375,18.875c0.15,8.689-4.27,14.479-6,22.75c-2.29,10.97,4.6,44.5-15.01,40.32
		c-4.3-0.91-8.18-2.391-12.63-2.61c-4.07-0.2-9.42,1.1-13.24-0.68c-8.22-3.811,0.06-16.099,1.63-21.998
		c1.99-7.5,3.85-16.422,2.61-24.103c-1.18-7.29-5.23-11.939-4.18-18.029c1.32-13.525-2.29-20.091-2.18-24.4
		c0.32-11.811,0.61-17.17,0.5-29c-0.1-10.3,3.15-14.08,2.14-24.29c-0.87-8.91-4.594-7.64-7.375-16.21
		c-0.32-0.05-2.736-12.69-3.386-14.14c-2.95-6.65-3.16-7.271-6.13-14.11c-2.93-6.75-8.07-27.53-10.5-34.25
		c-5.01-13.88-5.687-19.83-11.997-33.25c-6.32-13.46-18.333-13.15-26.363-26.04c-3.35-5.38-6.64-12.13-8.55-18.16
		c-1.72-5.439-3.56-16.54-2.76-22.18c0.52-3.67,2.56-6.72,2.7-10.51c0.16-3.98-1.08-7.57-0.54-11.62
		c1.06-7.82,5.13-14.521,1.51-22.32l1.67-1c1.46-1.62,2.99-3.26,4.68-4.64c25.27-20.8,41.41,19.05,62.74,25.13
		c19.15,5.46,59.02-18.88,64.95,11.85c1.95,10.12,0.84,21.46,1.71,31.79C263.71,529.25,259.73,535.37,254.58,545.12z"/>
				</g>
				<g id="Gürtel">
					<path fill="#16110E" d="M107.75,463.083c2.985,1.017,5.775,1.064,8.861,1.405c2.923,0.321,4.375,1.98,6.779,3.355
		c2.455,1.404,5.193,0.783,7.858,0.531c2.559-0.242,5.109-0.12,7.657,0.204c2.814,0.358,5.922,1.898,8.743,1.712
		c2.89-0.192,6.212-3.631,6.855-6.365c1.12-4.76-8.847-4.229-11.461-4.459c-7.769-0.686-15.334-1.962-22.705-4.566
		c-4.078-1.441-8.667-3.855-12.984-1.939c-2.299,1.021-5.886,3.931-4.28,6.761c0.256,0.451,0.708,0.856,1.093,1.195"/>
				</g>
				<g id="Tshirt">
					<path fill="#f0f0f0" d="M123.17,195.83l-3,10.67c0.54-2.4,1.08-4.79,1.63-7.19C122.24,198.15,122.7,196.98,123.17,195.83z" />
					<path fill="#f0f0f0" d="M215.23,175.34c-0.34,7.46-4.81,15.03-8.31,21.39c-4.21,7.67-8.87,15.09-13.03,22.79
		c-6.38,11.83-13.52,25.18-15.41,38.65c-0.6,4.25,0.05,8.31,0.16,12.46c0.98,1.72,1.81,3.69,2.48,5.95
		c7.31,24.56-8.94,54.19-13.82,78.08c-6.07,29.76-8.04,59.69-4.6,89.84c2.706,23.781-14.379,16.117-29.117,19.542
		c-9.94,2.311-16.792-3.125-26.083-6.291c-13.041-4.443-3.3-22.711-2.25-33.841c1.19-12.64-0.2-24.98,1.63-37.63
		c2.24-15.44,0.38-28.9-1.9-44.17c-2.24-15.01-2.81-30.86,0.39-45.79c1.41-6.59,0.29-9.283,2.63-15.653c-0.46-1-1.88-16.39-2-18
		c-0.72-9.24,3.284-21.507,4.333-30.667c1.76-15.29,5.937-18.041,11.396-32.521c0.02-0.06,0.04-0.11,0.07-0.17
		c-0.55,2.4-1.09,4.79-1.63,7.19l3-10.67c-0.47,1.15-0.93,2.32-1.37,3.48c0.57-2.49,1.14-4.99,1.71-7.48
		c2.54-9.62,4.51-22.4,13.47-28.21c2.55-1.65,4.65-1.92,7.66-1.42c-3.973,8.383-2.946,14.323,4.693,18.133
		c7.61,3.79,17.337,3.033,25.417,2.083c8.05-0.94,21.78-5.617,28.75-10.167C208.33,169.11,215.79,162.76,215.23,175.34z"/>
				</g>
				<g id="Hände">
					<path fill="#C6885D" d="M239.813,579.125c-2.728-1.331-3.342-3.36-4.345-6.03c-0.838-2.229-2.536-4.482-1.601-6.981
		c1.019-2.726,3.687-1.682,5.347-0.243c1.394,1.207,3.695,4.155,5.85,3.505c1.699-0.514-1.761,9.034-2.188,8.688L239.813,579.125z"
					/>
					<path fill="#C6885D" d="M32.667,523.333c2.21,1.384,6.076,5.771,8.951,3.852c3.553-2.372-2.617-5.923-4.371-6.9
		c-1.735-0.968-3.3-1.931-4.699-3.363c2.724,1.568,6.051,3.248,9.206,3.669c4.518,0.603,4.934-3.087,1.546-4.626
		c-0.968-0.439-5.432-2.849-5.738-4.103c-0.321-1.311,2.088-0.099,2.965-0.372c4.598-1.436-0.307-4.618-2.665-5.567
		c-3.445-1.386-6.301-3.06-8.483-6.194c-2.255-3.242-0.78-6.019,2.243-7.893c3.141-1.948,6.266-7.846,2.394-10.685
		c-2.428-1.779-6.318-0.987-8.647,0.557c-2.757,1.827-3.588,4.927-4.171,8c-1.344,7.083-2.689,14.553-0.113,21.514
		c1.768,4.777,4.973,7.539,8.661,10.811c0.657,0.583,1.294,1.187,1.922,1.802"/>
					<path fill="#e7ad81" d="M18.5,462c-1.151,4.278-1.879,8.614-2.883,12.921c-1.001,4.293-3.143,8.1-4.417,12.284
		c-1.269,4.165,0,7.292,1.304,11.249c1.609,4.883,1.679,9.815,2.566,14.818c0.727,4.097,2.979,6.036,6.077,8.564
		c2.882,2.352,6.941,7.249,10.898,7.56c4.164,0.326,3.38-4.29,1.705-6.615c-2.528-3.51-7.11-5.008-9.088-8.996
		c-3.734-7.529,1.305-12.102,3.224-19.368c1.364-5.166,1.614-8,2.261-7.921c3.255,0.398,1.059,8.8,0.353,15.129
		c-0.442,3.964,1.896,15.031,7.788,11.855c5.105-2.751,2.509-13.339,2.525-17.73c0.02-5.712,2.888-9.469,3.868-14.902
		c1.071-5.94-0.253-12.459-0.333-18.468c-0.01-0.745,0.023-1.494,0.04-2.239c-3.676,1.971-9.794,0.665-13.718,0.025
		c-3.536-0.576-7.092-5.424-10.239-2.599c-1.481,1.33-2.25,3.213-2.181,5.183"/>
					<path fill="#e7ad81" d="M245.667,525c-4.799,3.802-8.787,7.225-9.669,13.549c-0.831,5.956-2.174,11.281-3.748,17.076
		c-0.861,3.168-5.113,13.516-1.999,16.005c4.85,3.875,10.475-6.319,11.256-9.659c1.477-6.313,4.182-12.266,3.799-18.858
		c-0.688,5.855,0.558,11.833-0.496,17.6c-0.763,4.176-1.177,13.523-4.597,16.218c-1.933,1.522-16.111,2.727-9.32,7.469
		c3.702,2.585,14.15,1.892,17.76-0.697c4.612-3.308,5.451-11.167,7.328-16.159c0.254-0.675,0.35-1.577,0.617-2.246
		c-1.862,5.077-3.707,9.535-5.262,14.704c-0.63,2.094-12.041,3.934,0.378,7.149c2.374,0.614,5.642,0.119,7.203-1.385
		c1.926-1.854,2.42-5.227,3.348-7.64c-0.384,0.761-0.324,1.589-0.413,2.396c5.008-0.656,7.519-7.602,9.246-11.601
		c-0.374,0.815-0.54,1.709-0.75,2.558c4.312-2.406,6.005-6.609,9.078-10.237c3.78-4.462,3.635-7.675,3.741-13.469
		c0.103-5.627,1.614-10.729,2.174-16.237c0.284-2.789,0.282-3.219-2.475-3.319c-3.711-0.136-7.234,1.399-11.103,0.933
		C264.851,528.314,245.667,525,245.667,525z"/>
				</g>
				<g id="Schuhe" className='schuhe'>
					<path fill="#EAEAEA" d="M52.292,831.542c-2.417,1.375-3.198,7.189-1.979,10.958c1.582,4.89,5.134,7.025,9.688,8.563
		c10.844,3.662,24.258,1.382,33.701-4.625c8.549-5.438,11.751-11.876,19.736-17.063c8.505-5.525,15.711-5.313,25.25-7.625
		c7.51-1.819,21.75-7.813,20.936-12.375c-0.216-1.211-0.686-5.643-1.036-6.82c-5.559,4.951-15.479,5.288-22.174,8.18
		c-9.175,3.963-18.46,8.853-27.042,13.965c-7.396,4.406-14.33,12.716-22.67,15.107c-6.244,1.789-13.532,3.604-19.886,1.545
		c-4.008-1.299-14.626-3.687-13.815-9.351L52.292,831.542z"/>
					<path fill="#EAEAEA" d="M90.5,823.167c-5.189,1.943-11.445,3.502-14.859-2.178c-2.774-4.616-1.125-9.71,2.689-13.059
		c3.57-3.134,8.003-4.909,11.339-8.382c3.288-3.423,6.436-7.433,9.215-11.282c3.729-5.165,4.751-22.35,13.732-14.644
		c3.169,2.72,8.2,6.407,10.097,10.172c2.562,5.087-2.823,7.905-6.063,10.863c-9.892,9.029-6.859,26.12-22.449,29.03
		c-1.113,0.208-2.245,0.344-3.368,0.479"/>
					<path fill="#EFF1F4" d="M103.25,778.875c-3.749,6.364-7.554,12.761-12.598,18.205c-4.947,5.342-10.932,9.365-17.012,13.291
		c-5.99,3.866-7.796,5.295-16.033,12.818c-4.496,4.106-10.017,11.267-2.732,16.691c5.593,4.165,13.048,5.674,19.625,5.369
		c7.95-0.369,14.013-2.111,20.447-6.667c5.788-4.098,12.01-10.299,17.97-14.166c6.386-4.144,13.772-5.623,20.963-7.935
		c6.855-2.204,18.734-7.707,24.257-12.732c1.099-1-0.004-9.879-1.167-16.537c-0.326-1.868-0.723-3.724-1.117-5.578
		c-2.099,2.831-2.76,5.776-6.472,6.924c-3.147,0.973-6.818,1.508-10.094,1.764c-6.71,0.523-13.511-1.394-20.165,0.082
		c-6.445,1.428-12.46,3.461-15.844,9.313c-1.695,2.932-3.655,3.97-6.338,5.837c-2.403,1.672-4.183,3.966-5.878,6.309
		c-3.341,4.617-8.086,11.227-12.504,3.427c-0.304-0.536-0.708-1.038-1.075-1.533c4.353-3.958,7.337-9.06,12.276-12.462
		c4.58-3.154,8.111-8.251,10.998-13.019c1.545-2.552,3.602-6.023,2.658-8.797L103.25,778.875z"/>
					<g>
						<path fill="#D8D8D8" d="M78.125,811.375c-0.188,0.078-0.325,0.205-0.41,0.382c1.206,1.91,2.54,3.612,4.002,5.327
			c1.385,1.625,2.512,4.122,4.268,5.338c2.3,1.593,4.814,0.521,3.12-2.256c-1.245-2.043-3.929-3.127-5.725-4.605
			c-1.876-1.545-3.144-3.553-5.365-4.67c-0.081,0.318,0.069,0.548,0.109,0.734"/>
						<path fill="#D8D8D8" d="M86.125,817.625c-0.844-1.532,0.066-3.786-1.147-4.75c-0.951-0.755-3.998-0.387-5.215-0.74
			c1.478-2.108,3.918,0.753,4.384-2.311c0.102-0.671-1.08-2.281-0.207-2.832c0.882-0.556,1.591,1.056,1.873,1.585
			c1.309,2.46,0.835,2.438,3.817,3.191c0.833,0.211,2.336-0.083,1.939,1.244c-0.452,1.511-2.223,0.357-3.053,0.397
			c-3.93,0.188-0.137,2.345-1.767,4.465"/>
						<path fill="#D8D8D8" d="M90.398,808.348c-0.567-0.732-11.089-1.93-6.311-3.702c1.561-0.579,4.436,0.62,6.176,0.479
			c-1.451-4.004-1.144-6.105,1.432-3.087c1.155,1.353,0.653,1.654,2.565,2.187c0.693,0.192,3.264,0.01,3.415,0.942
			c0.159,0.981-3.721,1.95-4.425,2.333c0.181,2.283,3.547,9.812,1.219,9.031C92.363,815.826,91.377,809.61,90.398,808.348z"/>
						<path fill="#D8D8D8" d="M98.875,809c-2.217,3.946-2.784-4.978-2.88-5.19c-1.027-2.272-4.552-0.469-5.701-3.563
			c1.141-0.809,2.655-0.388,3.937-0.765c-0.501-1.413,0.031-2.97,1.573-2.094c1.184,0.671,0.748,2.536,2.041,3.241
			c0.813,0.442,3.613-0.478,3.772,0.898c0.153,1.329-2.886,1.389-3.701,1.235c0.24,2.438,0.852,4.423,0.71,6.987"/>
						<path fill="#D8D8D8" d="M104.965,805.589c-2.045,0.442-3.14-2.968-3.652-4.369c-0.621-1.699-0.694-3.94-2.564-4.414
			c-1.232-0.312-2.536-0.384-3.801-0.44c0.549-1.276,2.672-0.811,3.71-1.149c1.471-0.481,0.38-2.218,0.453-3.538
			c0.15-2.691,1.014-0.819,1.503,0.626c0.282,0.834,0.839,2.887,1.682,3.247c1.353,0.58,3.154-0.976,4.508-0.665
			c0.186,2.368-3.939,0.391-3.419,2.618c0.405,1.733,1.304,3.36,1.592,5.205c0.153,0.979,0.646,1.941,0.148,2.854L104.965,805.589z"
						/>
						<path fill="#D8D8D8" d="M110.906,798.609c-1.23-0.066-1.916-1.033-2.627-1.988c-0.818-1.097-1.628-2.121-2.549-3.133
			c-0.768-0.844-1.101-1.781-1.642-2.762c-0.722-1.31-1.926-2.001-3.09-2.867c-0.541-0.402-2.109-1.627-0.823-2.079
			c1.104-0.388,2.623,0.934,3.32,1.616c1.612,1.581,2.908,3.478,4.549,5.029c1.735,1.639,3.333,3.235,3.174,5.878
			C111.203,798.572,110.906,798.609,110.906,798.609z"/>
					</g>
					<path fill="#EAEAEA" d="M188.591,892.219c-0.603,2.825-0.282,6.623-0.333,9.522c-0.054,3.004,0.468,6.019,1.773,8.74
		c1.399,2.917,3.637,5.354,6.375,7.068c2.986,1.868,6.464,2.851,9.959,3.126c3.522,0.277,7.108-0.146,10.478-1.204
		c3.091-0.972,5.986-2.502,8.514-4.531c2.486-1.996,4.585-4.433,6.395-7.048c1.936-2.799,3.574-5.817,4.888-8.956
		c1.328-3.17,2.306-6.487,2.794-9.894c0.475-3.308,0.45-6.643,0.262-9.973c-0.045-0.793-0.099-1.585-0.152-2.378
		c-1.09,7.191-6.3,15.753-12.802,19.1c-5.242,2.698-10.914,1.662-16.475,0.879c-4.404-0.62-8.874-0.723-13.233-1.654
		c-2.67-0.57-5.305-1.34-7.951-2.016L188.591,892.219z"/>
					<path fill="#EAEAEA" d="M212.25,880.625c-5.091-1.975-10.772-6.406-11.249-12.432c-0.541-6.849,2.855-13.684,4.399-20.217
		c1.4-5.922,1.086-11.18-1.034-16.863c-1.51-4.049-3.987-8.874-1.832-13.152c2.281-4.526,8.298-5.815,12.91-5.723
		c4.988,0.1,10.713,1.842,13.595,6.215c2.672,4.055,1.692,8.834,0.187,13.119c-1.938,5.518-4.005,10.659-4.47,16.567
		c-0.574,7.306,0.766,14.704-0.166,21.979C223.804,876.265,212.25,880.625,212.25,880.625z"/>
					<path fill="#EFF1F4" d="M199.156,818.333c4.012,7.124,9.253,13.219,9.223,21.832c-0.033,9.324-2.156,17.971-4.072,26.977
		c-0.902,4.241-0.043,7.468,4.265,9.161c3.462,1.361,9.076,1.568,11.254-2.055c2.088-3.476,0.391-9.025,0.566-12.854
		c0.208-4.54,1.751-8.754,2.555-13.187c1.688-9.313,0.759-17.197,8.535-24.016c0.792-0.694,1.623-1.349,2.451-1.999
		c0.639,6.808,1.275,13.616,2.044,20.411c0.775,6.842,1.766,13.653,2.626,20.484c0.873,6.928,1.974,11.088,1,18.049
		c-0.977,6.989-4.035,15.302-9.354,20.051c-5.604,5.002-10.457,7.977-17.938,8.063c-6.577,0.076-14.931-0.068-19.063-5.5
		c-4.02-5.284-5.295-11.218-4.375-17.5c1.067-7.295,2.923-16.388,4.613-23.549c1.583-6.706,2.674-13.48,3.386-20.332
		c0.671-6.456,1.154-12.933,1.753-19.396c0.143-1.542,0.289-3.083,0.434-4.624L199.156,818.333z"/>
					<g>
						<path fill="#D8D8D8" d="M223.125,874.375c-3.71,0.543-6.792-0.269-10.415-0.911c-3.177-0.563-8.483,0.706-11.238-0.954
			c-3.708-2.236,3.232-2.409,4.808-2.162c3.582,0.563,7.145,0.963,10.75,1.377c1.34,0.153,10.415,0.76,5.72,3.025"/>
						<path fill="#D8D8D8" d="M206.75,855.167c1.551-0.907,3.761-2.413,5.482-2.866c1.648-0.434,4.295,0.568,5.888,1.103
			c0.711,0.239,1.94,1.118,2.635,1.128c1.26,0.017,1.468-0.979,0.579-1.766c-0.896-0.793-5.623-1.031-4.601-2.95
			c0.327-0.613,2.736-1.275,3.438-1.454c1.203-0.307,2.435-0.1,3.648,0.043c-0.269-0.711-2.479-1.407-3.349-1.582
			c-1.314-0.266-2.436,0.18-3.636,0.703c-2.218,0.966-5.189,1.539-7.536,0.596c-1.204-0.483-2.432-1.907-3.789-1.775
			c-0.269,1.726,3.454,1.801,2.854,3.902c-0.411,1.438-3.752,2.165-3.261,3.924c0.261,0.932,1.639,1.393,2.23,0.578"/>
						<path fill="#D8D8D8" d="M216.5,833.75c-2.394-0.336-4.785-0.751-7.184-1.043c-2.148-0.263-4.744,0.209-6.791-0.484
			c-1.609-0.545-3.431-2.426-1.132-3.485c1.527-0.704,4.008,1.015,5.483,1.386c3.837,0.967,7.962,1.578,11.903,1.253
			c1.961-0.161,11.557-1.991,12.449,0.741c0.656,2.009-4.71,0.937-5.733,0.877c-2.5-0.145-4.806,0.4-7.267,0.739
			c1.024,1.303,4.349,1.652,5.951,2.565c1.477,0.843,4.217,2.114,5.058,3.582c-2.663,0.022-4.818-1.681-7.12-2.794
			c-2.932-1.417-4.447-1.53-7.579-0.89c-1.597,0.327-2.98,0.121-4.579,0.162c-1.564,0.039-2.976,0.723-4.505,0.803
			c-0.751,0.04-2.957,0.137-2.313-1.117c0.523-1.019,3.936-0.291,4.791-0.275c2.269,0.042,7.291,0.258,8.442-2.145"/>
						<path fill="#D8D8D8" d="M202.5,863.875c-1.316,0.844-1.795-0.366-1.242-1.445c0.374-0.729,2.008-1.24,2.691-1.517
			c1.931-0.78,4.052-0.979,5.86-2.069c-1.424-0.196-6.547-1.472-7.17-2.836c-1.413-3.096,3.885,0.035,4.817,0.443
			c2.618,1.145,5.119,1.229,7.883,0.667c1.682-0.342,7.02-2.61,8.516-1.453c2.001,1.547-5.079,2.307-6.29,2.808
			c2.388,0.491,8.443,4.108,8.297,6.884c-3.382-0.026-7.386-3.028-10.536-4.233c-3.901-1.492-6.127,0.12-9.87,1.289
			c-1.238,0.387-3.737,1.84-4.831,1.713"/>
						<path fill="#D8D8D8" d="M214.16,868.87c-0.02,0.09-0.07,0.18-0.13,0.28c-0.52,0.83-3.93,0.859-4.87,1.14
			c-1.82,0.55-3.11,1.05-5.06,0.96c0-0.72,2.11-1.28,2.81-1.6c1.32-0.601,2.66-1.74,4.08-2.051c0.2-0.04,0.47-0.069,0.78-0.069
			c-1.9-1.021-3.75-2.03-5.45-3.471c-0.08-0.34-0.13-0.699-0.18-1.02c2.29,0.58,3.94,2.83,6.17,3.59c2.65,0.91,4.94-0.649,7.55-0.49
			c0.64,1.95-2.36,2.29-3.64,1.78c0.7,0.65,3.73,2.021,3.85,2.85C220.38,873,215.84,869.96,214.16,868.87z"/>
						<path fill="#D8D8D8" d="M201.917,847c3.973-1.727,8.241-3.77,12.413-5.002c1.075-0.316,2.236-0.438,3.275-0.798
			c-0.48,0.151-0.957,0.313-1.429,0.481c1.923,3.239,4.979,5.05,8.123,6.829c0.811,0.459,3.554,2.563,3.958,0.602
			c0.291-1.415-3.229-3.339-4.165-4.034c-1.706-1.268-3.732-1.874-5.459-3.099c1.988-0.775,4.408-0.124,6.439-0.829
			c0.004-2.684-1.78-2.006-3.637-1.586c-1.894,0.429-4.142,0.634-6.079,0.406c-1.856-0.219-3.09-1.565-4.864-2.064
			c-0.923-0.26-4.326-1.523-3.347,0.157c0.369,0.633,2.974,2.716,3.767,2.92c-2.404,0.311-3.844,0.652-5.892,1.933
			c-0.973,0.608-4.408,2.159-4.273,3.496c0.121,1.192,1.731,1.074,2.504,0.671"/>
					</g>
					<path fill="none" d="M217.625,836.25c-3.487,0.99-7.299,1.703-10.526,3.41c-1.446,0.765-7.546,4.59-6.653,6.722
		c1.109,2.646,6.465-1.958,7.537-2.824c2.421-1.958,5.106-3.448,8.087-4.403c3.69-1.182,7.173-0.813,10.637,0.873
		c-1.049,1.158-8.57-2.128-10.706-2.652"/>
					<path opacity="0.1" fill="#FFFFFF" d="M206.5,864.62l-0.25-0.62c0.02,0.02,0.05,0.04,0.07,0.06
		C206.36,864.26,206.42,864.45,206.5,864.62z"/>
					<path opacity="0.1" fill="#FFFFFF" d="M204.11,871.34l-0.11-0.09h0.1C204.1,871.28,204.1,871.31,204.11,871.34z" />
					<path opacity="0.1" fill="#FFFFFF" d="M204.88,872c-0.48-0.22-0.71-0.44-0.77-0.65L204.88,872z" />
					<path fill="#DBDBDB" d="M206.667,841.917c-1.217,4.418-6.791,10.568-3.595,15.117c1.501,2.137,6.052,1.746,3.804-0.855
		c-0.883-1.021-2.4-1.248-2.612-2.784c-0.173-1.256,0.867-2.62,1.308-3.734c0.7-1.771,2.847-5.843,1.345-7.493"/>
					<path fill="#DBDBDB" d="M203.625,868.969c1.429-3.612,2.506-7.063,2.785-11.15c0.309-4.545-1.614-8.859-0.644-13.424
		c0.89-4.186,0.466-8.894,1.859-12.904c1.32-3.8,5.148-8.547,9.679-6.414c3.185,1.498,4.209,4.516,3.03,7.632
		c-0.132,0.349,1.833,1.917-0.306,3.116c-1.84-0.637-2.175,2.067-2.668,3.366c-0.588,1.547-1.082,3.109-1.881,4.558
		c-1.802,3.264-2.882,6.246-4.104,9.764c-0.604,1.738-2.113,3.958-3.531,5.145c0.488-1.498-0.052-2.673,0.531-4.001
		c0.578-1.316,2.473-3.152,3.014-4.567c0.558-1.461,0.43-3.01,1.091-4.424c0.674-1.442,1.535-2.722,2.132-4.225
		c0.582-1.464,1.727-3.075,1.792-4.663c-2.584,2.239-5.949,3.801-7.971,6.517c0.035-0.972,0.562-1.918-0.258-2.709
		c2.709-2.661,7.688-4.057,9.741-7.251c2.975-4.631-3.432-8.974-6.648-4.536c-2.366,3.264-2.739,9.506-2.521,13.4
		c0.13,2.334-0.247,4.417-0.54,6.714c-0.299,2.347,0.156,2.823,0.42,5.12c0.49,4.262-0.19,2.518-1.19,6.594
		c-0.129,0.525-2.614,8.316-2.738,8.843c-0.182-0.089-0.204-0.095-0.366-0.217L203.625,868.969z"/>
					<path fill="#DBDBDB" d="M218.167,835.583c-1.99,1.631,1.403,6.896,1.882,8.822c0.824,3.309,1.775,6.582,2.25,9.951
		c0.418,2.977,2.063,5.676,3.603,8.208c1.601,2.632,1.762,5.521,3.047,8.227c0.65,1.368,2.002,2.232,2.994,3.36
		c0.89,1.012,1.617,2.621,2.601,3.472c0.246,0.213,0.425,0.496,0.69,0.703c0.184-0.271,0.429-0.828,0.508-1.143
		c-2.588-1.393-4.952-4.79-5.641-7.604c-0.752-3.069-0.957-5.883-2.264-8.838c-1.298-2.934-2.929-5.803-3.995-8.828
		c-0.907-2.576-1.018-5.626-2.22-8.039c-0.637-1.277-1.593-2.369-2.109-3.72c-0.532-1.392-0.473-2.792-0.69-4.231
		c-0.051-0.339,0.036-0.76,0.084-1.097c-0.445-0.184-0.69,0.007-0.989,0.34"/>
					<path fill="#DBDBDB" d="M219.479,832.626c3.18-2.647,6.961-2.989,11.052-3.487c3.824-0.465,9.646-1.423,12.763,1.515
		c2.594,2.444,1.83,7.261-1.913,8.003c-3.928,0.779-7.57-2.883-10.922-4.313c-3.69-1.574-7.071-0.853-10.75,0.296
		c-0.433,0.135-0.859,0.3-1.285,0.453c2.861-2.768,6.032-3.992,9.988-2.873c3.349,0.947,5.727,3.762,9.042,4.686
		c2.795,0.779,7.572-0.934,5.344-4.549c-2.183-3.541-8.125-1.949-11.513-1.564c-3.863,0.439-12.305,3.118-12.701,3.334
		L219.479,832.626z"/>
					<path fill="#DBDBDB" d="M113.492,789.702c1.507,0.682,3.928,0.959,5.116,2.128c1.565,1.539,0.873,2.623-1.231,2.508
		c-1.505-0.083-3.442-1.309-4.776-1.987c-1.794-0.912-3.409-2.144-5.326-2.804c-2.128,3.035-4.847,6.491-8.032,8.422
		c-3.786,2.295-6.565,3.302-9.003,7.176c-1.099,1.745-2.763,3.68-2.729,5.843c2.469-0.983,3.453-4.519,4.297-6.797
		c1.021-2.756,1.742-4.163,4.298-5.677c-0.818,4.147-2.924,8.069-4.513,11.972c-0.794,1.95-2.14,4.446-4.631,4.261
		c-2.354-0.175-3.237-2.445-2.809-4.501c0.688-3.3,4.946-8.293,7.37-10.634c3.057-2.952,7.476-3.689,9.526-7.681
		c1.631-3.176,5.76-11.181,7.138-4.774"/>
					<path fill="#DBDBDB" d="M105.375,785.25c-3.27-0.405-1.542-4.998-3.572-6.365c-0.69,1.982-0.647,3.271-0.036,5.297
		c0.335,1.108,1.554,5.539,2.607,2.318"/>
					<path fill="#DBDBDB" d="M85.375,812c0.601,3.503-3.475,6.95-3.922,10.459c-0.4-0.081-1.141-0.525-1.406-0.769
		c2.125-5.063,4.485-9.993,6.776-14.981c2.525-5.497,6.399-9.468,9.498-14.538c1.572-2.573,2.877-5.456,5.793-6.771
		c1.088-0.491,5.627-1.469,5.215,0.912c-0.3,1.734-5.707,2.714-7.01,3.432c-2.7,1.485-3.932,3.896-5.509,6.417
		c-3.188,5.091-6.747,9.031-8.633,14.854c-0.187,0.578-0.37,1.157-0.552,1.736"/>
				</g>
				<g id="Jacke">
					<path fill="#24201D" d="M295.3,202.33c-10.45,8.15-16.39,20.95-21.76,32.84c-2.75,6.12-4.56,12.51-7.89,18.37
		c-3.4,6.01-4.077,12.677-5.316,19.627c-1.29,7.26-4.523,11.873-7.653,18.693c-2.9,6.33-2.417,10.495-1.078,17.685
		c1.22,6.48,0.967,13.065,1.527,19.775c0.59,7.14,3.79,20.854,3.87,28.014c0.17,13.16-0.19,19.566,0.04,32.646
		c0.01,0.68,0.08,1.37,0.18,2.06c7,12.91,1.68,29.39,0.23,43.08c-0.92,8.689-0.15,19.28-1.91,28.7c0,4.489-0.66,9.109-1.55,13.02
		c-1.22,5.391-1.46,11.391-3.52,16.521c-2.11,5.26-7.83,6.84-12.24,9.729c-5.24,3.43-9.53,5.75-15.75,6.93
		c-7.06,1.341-14.3,3.54-21.54,3.381c-6.18-0.131-12.44-1.44-18.6-2.07c-5.76-0.58-11.31-2.34-17.07-2.82
		c-6.42-0.54-12.93-0.029-19.38-0.399c-0.27-1.65-0.75-3.271-0.89-4.92c-0.28-3.4,0.53-6.78,0.5-10.2
		c-0.01-1.431-1.42-9.021,1.26-7.12c0.45-1.23,0.25-2.851,0.36-4.18c0.2-2.36,0.37-4.82,0.5-7.19c0.03-0.49,0.01-1.03-0.04-1.61
		c0.42-4.765-0.15-8.822,0.17-13.202c0.95-13.12,0.956-25.269,1.875-38.438c0.938-13.438,1.455-26.877,2.625-39.438
		c0.77-8.31,0.515-14.417,1.625-22.688c-0.41-9.23,1.295-18.765,1.875-25.875c1.86-23.01,3.92-37.58,6.58-61.08
		c2.51-22.07,5.107-45.795,19.795-60.732c15.383-15.645,34.425-29.127,46.625-47.978c1.23-1.9,0.91-0.35,2.08-2.29l4.42,2.71
		c8.94,1.93,19.01,7.02,27.72,10.65c8.54,3.55,0.91,4.35,21.91,15.09C289.68,192.08,292.05,198.07,295.3,202.33z"/>
					<path fill="#16110E" d="M193.333,249.333c-0.813,4.848-3.276,17.262-1.711,19.986c1.253,2.181,6.035,2.423,8.806,3.007
		c3.303,0.696,7.63,2.985,10.526,4.655c5.499,3.17,8.214,5.696,14.712,4.519c3.379-0.611,6.783-2.799,10.083-3.667
		c3.453-0.908,8.402-1.043,11.917-0.833c5.653,0.338,5.316-13.303,5.958-19.125c0.08-0.72-14.841-3.655-22.351-4.701
		c-8.579-1.194-3.903-1.471-12.58-1.705c-4.507-0.121-19.725-2.815-24.278-2.254L193.333,249.333z"/>
					<path fill="#16110E" d="M45,475.001c-8.447-8.039-24.342-2.702-33.892-10.649c4.637-5.674,5.854-9.328,5.892-16.682
		c0.029-5.751-0.066-13.391,3.209-18.406c1.521-2.33,4.232-3.012,5.377-5.619c1.303-2.967-1.168-5.813-0.619-8.586
		c0.917-4.631,3.48-3.142,5.478-6.414c1.253-2.051,1.076-7.014,1.241-9.438c0.335-4.905-1.651-19.326,3.694-19.238
		c-1.635-4.11,1.204-4.565,1.528-7.213c0.284-2.323-1.113-4.356-0.947-7.088c0.183-3,3.564-4.703,3.816-7.113
		c0.282-2.694-3.083-5.572-3.138-8.692c-0.095-5.496,5.02-10.569,6.819-15.56c2.023-5.612,2.389-11.558,3.627-17.334
		c2.623-12.243,7.824-22.667,8.554-35.288c0.646-11.184,7.168-21.304,9.919-32.064c2.776-10.859,4.002-27.641,12.846-35.563
		c-0.065-0.095,0.96,0.346,0.87,0.351c-1.154,22.979,1.993,46.667,4.39,69.498c2.794,26.616,9.015,52.034,4.558,78.924
		c-1.673,10.091-5.09,19.71-7.09,29.708c-1.347,6.728,0.227,14.341-1.743,20.816c-1.898,6.238-7.024,7.788-11.454,11.788
		c-4.439,4.009-6.359,9.477-8.826,14.789c-4.866,10.48-6.451,22.268-11.837,32.428c-0.579,1.092-1.123,2.203-1.715,3.289L45,475.001
		z"/>
					<path fill="#24201D" d="M111.92,237c-0.31,18.99-5.22,37.54-5.27,56.48c-0.03,9.68,1.58,19.23,1.85,28.89
		c0.26,9.31-0.63,18.54,0.14,27.85c0.46,5.65,2.12,11.83-1.79,14.91c1.42,8.88,0.6,19.25,0,27.4c-0.82,11.03-1.46,21.74-1.74,32.81
		c-0.27,10.98-1.83,22.07,0.48,32.94c2.38,11.2,6.99,18.75-0.58,29.09c-4.926-0.953-6.493-1.793-10.843-1.763
		c-4.72,0.03-10.243,0.314-14.083-2.357c-5.75-4-5.704-3.014-8.083-5.083c-6.13-5.34-5.417-19.417-1.77-19.407
		c-2.82-4.25,0.33-6.42,0.19-9.93c-0.16-3.78-1.28-5.66-0.36-9.84c1.54-6.94,0.34-13.58,1.01-20.51c0.35-3.56,2.64-6.68,2.69-10.23
		c0.04-3.5-1.34-6.81-1.26-10.5c0.15-7.01,1.99-13.55,0.99-20.52c-0.6-4.21-0.53-5.42,0.77-9.36c0.78-2.35-0.19-5.35,0.08-7.7
		c-0.69-4.96,3.01-12.33,3.05-16.64c0.09-8.96-4.11-17.28-5.33-26.06c-0.26-1.89-0.42-3.81-0.56-5.72l-0.06-2.56
		c-1.32-9.79-4.04-16.9-3.2-26.83c0.44-5.14,2.25-10.06,2.69-15.2c0.44-5.05-0.59-9.88-1.23-14.86c-1.25-9.73,0.27-18.98,2.56-28.43
		c2.22-9.18,2.35-21.09,7.56-29.14c4.66-7.21,15.11-13.92,22.98-17.12c1.1-0.45,2.16-1.2,3.2-1.77
		C112.23,195.59,112.25,216.45,111.92,237z"/>
					<path fill="#D7C5A1" d="M144.272,149.302c-1.501-3.052-2.939-3.76-4.835-5.052c-3.146-2.144-1.458,0.88-4.397-0.47
		c-0.39,0.42-0.74,1.12-1.03,1.62c-0.59-0.06-0.93-0.28-1.54-0.18c-0.12,0.93-1.05,1.96-1.22,2.69c-0.47-0.42-1.44-0.69-1.83-1
		c-0.41,1.96-1.25,3.97-3.45,3.88c-0.08,2.55-11.84,12.17-14.7,11.5c-0.01,0.66-0.17,1.53-0.04,2.17c-2.36,0.77-2.2,3.15-3.79,5.04
		c-0.7,0.83-4.16,2.88-5.16,3.23c0.14,0.55,0.44,1.58,0.7,2.05c-0.62,1.7-2.18,4.83-0.76,6.93c0.02,0.83,0.03,2.22,0.75,3.57
		c-2.78,1.99,0.38,7.73-1.43,10.67c0.19,0.4,0.73,0.93,0.94,1.3c-2.95,3.56,2.77,14.06-0.64,15.37c0.37,0.46,0.68,0.97,1.13,1.38
		c-2.05,3.35-0.86,15.37,1.94,18.43c-1.11,2.59,0.47,5.13,0.3,7.78c-0.21,3.19-0.69,5.03-0.52,8.28c0.29,5.33-0.33,9.81-0.18,14.97
		c0.15,5.4-1.91,9.8-2,14.92c-0.09,5.41,0.92,11.03,0.82,16.6c-0.07,3.58-2.24,13.34,0.42,15.77l0.88,4.52
		c-0.26,0.36-0.64,0.74-0.65,1.15c-0.01,0.71,0.83,1.13,0.86,1.75c0.16,2.7,0.29,5.71,0.28,8.31c0,1.49-0.37,2.39-0.13,4.04
		c0.2,1.31,0.83,2.88,0.71,4.15c-0.1,1.14-0.44,2.65-0.56,3.68c-0.19,1.61,0.02,2.32,0.1,3.6c0.18,2.96,0.28,5.03-0.09,8.06
		c-0.33,2.73,0.88,4.69,1.3,7.67c0.4,2.8-0.73,5.48-0.49,8.19c0.11,1.32,0.33,2.78,0.36,4.32c0.02,1.66-0.15,2.88-0.21,4.49
		c-0.05,1.59,0.31,3.03,0.29,4.67c-0.02,1.22-0.94,2.83-0.78,4.13c0.19,1.55-0.36,7.96,0.36,8.51c-0.99,1.85-0.93,3.95-0.82,6.01
		c0.12,2.17,0.01,3.38-0.33,5.69c-0.3,2.08,0.15,4.09,0.62,6.27c0.55,2.47-0.34,3.54-0.67,5.89c-0.53,3.8,0.12,7.94-0.41,11.83
		c-0.3,2.21,0.27,3.94,0.16,6.15c-0.1,2.189-0.55,3.979-0.48,6.18c0.15,4.01-1.17,9.29,0.61,12.84c-0.08,0.381-0.23,0.75-0.17,1.221
		c0.09,0.71,0.72,1.13,0.74,1.84c0.02,0.7-0.49,1.39-0.48,2.149c0,0.341,0.32,0.65,0.32,1.03c-0.01,0.42-0.44,0.8-0.43,1.19
		c0.03,1.05,0.65,0.85,0.94,1.7c0.24,0.699-0.25,1.34-0.01,2.06c0.19,0.61,0.8,1.3,1.05,1.95c0.3,0.77-0.03,1.62,1.24,1.17
		c1.52-0.54,0.95-3.78-0.56-3.98c0.1-0.39,0.79-1.02,0.79-1.359c-0.02-0.67-0.86-0.83-1.02-1.49c-0.05-0.22,0.33-1.16,0.3-1.55
		c-0.02-0.41-0.34-0.88-0.37-1.4c-0.11-1.74-0.18-3.45-0.75-5.16c0.77-1.79-0.4-4.75-0.38-6.54c0.03-2.64,0.38-5.489,0.46-8.18
		c0.16-5.5,0.59-11.3,0.72-16.67c0.03-1.32-0.04-2.74-0.05-4.16c-0.02-1.98,0.46-1.86,0.97-3.67c0.37-1.32,0.25-2.94,0.21-4.34
		c-0.02-0.68-0.71-1.24-0.69-1.87c0.03-0.88,1.01-0.79,1.06-1.81c0.15-2.89-0.34-5.67-0.01-8.52c0.36-3.01,1.32-4.71,0.78-7.75
		c-0.58-3.21,0.1-6.05,0.52-9.37c0.11-0.88-0.05-1.84,0.05-2.68c0.07-0.64,0.56-1.95,0.59-2.48c0.06-0.82-0.79-1.1-0.78-1.85
		c0.02-0.98,0.94-1.1-0.02-2.33c2.28-2.93,0.51-8.32,0.71-12.11c0.09-1.78,0.22-2.87-0.05-5.15c-0.21-1.87-0.06-4.14-0.11-6.01
		c-0.07-2.27-0.16-4.57-0.31-6.9c0.19-0.91,0.5-1.73,0.46-2.72c-0.11-2.21-1.43-3.64-0.15-6.08c-1.1-1.41,0.45-3.33,0.37-5.14
		c-0.09-2.29-3.39-2.68-0.44-5.14c-1.72-1.95-0.61-5.1-0.62-7.36c-0.02-4.38-0.17-7.96,0.17-12.44c0.22-3.02,0.29-10.71,2.2-12.7
		c-0.15-0.51-0.58-0.71-0.63-1.26c2.25-1.29,2.22-6.64,2.44-9.13c0.2-2.14-0.84-7.68,2.51-9.1c-0.23-1.77,0.31-4.63,0.4-6.63
		c0.05-1.22,0.14-2.38,0.17-3.88c0.02-1.73-0.82-1.33,0.16-2.8c0.02-1.46,0.12-2.02,0.5-3.4c-1.62-1.53-2.06-3.83,0.27-4.8
		c1.06-2.09-0.09-10.15,3-10.84c-0.84-1.92-0.73-4.02,1.48-4.71c-0.63-2.36,1.36-10.1,3.02-11.46c-0.25-5.5,3.53-11.39,3.05-17.7
		c0.49-0.08,1.01-0.04,1.5-0.08c-0.76-2.66,2.23-10.4,3.3-13.45c0.76-2.16,1.66-3.95,2.1-6.26c0.6-3.2,1.38-3.8,2.69-6.5
		c1.1-2.27,1.21-4.13,3.08-6.18c2.07-2.27,3.318-2.897,3.591-6.111C145.178,156.479,145.77,152.346,144.272,149.302z M110,342.75
		c-0.12-0.4-0.19-0.77-0.22-1.12L110,342.75z"/>
					<path fill="#D7C5A1" d="M110,342.75c-0.12-0.4-0.19-0.77-0.22-1.12L110,342.75z" />
					<path fill="#D7C5A1" d="M110,342.75c-0.12-0.4-0.19-0.77-0.22-1.12L110,342.75z" />
					<path fill="#D7C5A1" d="M238.27,167.04c-1.16,0.79-0.88,3.51-0.62,4.97c-0.25,0.09-0.55,0.08-0.79,0.18
		c0.97,3.04-2.49,2.27-2.18,5.45c-0.43-0.04-0.98,0.19-1.32,0.18c-0.07,1.65-0.19,3.5-1.98,3.51c-1.07-0.55-1.76-1.23-1.78-2.31
		c-2.82-0.88-4.48-2.86-5.12,0.83c-0.99,0.22-1.4,0.82-1.5,2c-0.26,0.16-0.49-0.08-0.79,0c-0.15,1.37-1.2,2.03-2.53,1.84
		c-0.21,2.12-3.71,2.43-4.98,3.4c-1.02,0.78-4.22,3.09-4.53,4.38c-1.42-0.3-2.7,0.04-2.97,1.51c-0.44-0.13-1.24-0.1-1.65-0.29
		c-0.23,0.22-0.22,0.7-0.34,0.96c-0.19-0.03-0.81-0.31-0.99-0.25c-0.97,1.75-2.91,2.37-4.22,3.5c-1.46,1.27-2.42,2.6-3.77,4.12
		c-0.7,0.78-1.39,1.58-2.07,2.19c-0.37,0.33-1.29,0.02-1.64,0.33c-0.54,0.47-0.01,1.2-0.45,1.61c-0.83,0.78-1.47,0.97-2.05,1.74
		c-0.56,0.75-0.43,1.65-1.17,2.49c-1.06,1.21-3.92,3-5.64,3.14c-0.66,1.96-6.03,8.62-8.34,9.13c-0.17,0.93-2.39,5.81-4.01,7.19
		c-1.07,4.59-1.51,9.21-2.58,13.88c-1.19,5.16-2.86,10.35-3.76,15.8c-0.94,5.72-1.23,11.33-1.88,17.04
		c-0.66,5.76-0.86,11.81-1.94,17.33c-1.08,5.52-1.44,10.7-2.23,16.18c-0.12,0.08-0.04,1.24-0.14,1.28
		c-0.37,4.9-1.19,8.39-1.46,13.32c-0.35,6.58-0.43,10.86-1.09,15.9c-0.62,4.84-0.49,9.68-1.02,14.55
		c-0.52,4.83-0.51,9.71-1.09,14.53c-0.31,2.54-0.49,5.01-0.57,7.56c-0.04,1.16,0.09,2.69-0.16,3.8c-0.11,0.5-0.24,0.76-0.4,0.88
		c0.38,6.59,0.13,13.25-0.51,19.78c-0.7,7.21-1.33,14.49-1.7,21.72c-0.39,7.55-0.19,15.129-0.49,22.69
		c-0.29,7.26-0.86,14.58-1.17,21.87c-0.14,3.56-0.2,6.97-0.68,10.51c-0.24,1.75-0.3,3.4-0.39,5.14c-0.07,1.271-0.64,5-1.68,1.641
		c0.29-0.24,0.57-0.67,0.88-0.91c-0.74-0.6-0.85-1.25-1.43-1.83c0.21-0.22,0.52-0.43,0.76-0.63c-0.03,0-0.22-0.4-0.22-0.46
		c1.58-0.63,0.91-3.08,0.34-4.42c1.32-0.811,1.4-2.79,0.5-3.82c0.81-1.34,0.45-2.6-0.34-3.84c0.23-0.45,0.39-1.1,0.58-1.47
		c-0.1-0.15-0.51-0.311-0.66-0.521c1.28-1.75-0.34-3.78-0.15-5.5c0.33-3.1,0.95-6.6,1.33-9.41c0.3-2.16-1.08-8.22,0.28-9.25
		c-0.8-1.5,0.85-2.21-0.42-3.64c0.1-0.2,0.54-0.46,0.67-0.66c-1.05-1.01-0.73-4.18-0.52-5.97c0.47-4.1,0.84-7.28,0.84-11.56
		c-0.01-3.44-0.17-8.69,1.81-11.34c-1.14-1.59-0.93-4.67-0.18-6.14c-0.35-0.96-0.33-1.79-0.25-2.67c0.05-0.18,0.45-0.17,0.55-0.36
		c-0.35-1.16-0.33-0.86-0.13-1.97c-0.18-0.33-0.49-0.54-0.63-0.88c0.43-0.2,0.74-0.59,1.1-0.82c-1.03-1.29-0.68-4.69-0.34-6.1
		c-0.14-0.23-0.39-0.27-0.55-0.52c1.14-2.75-0.21-5.95,1-8.72c-0.01-0.3,0-0.62,0.03-0.91c0.1-1.13,0.51-2.39,0.39-3.41
		c-0.07-0.64-0.52-0.79-0.34-1.62c0.14-0.62,0.68-1.03,0.75-1.65c0.1-0.99-0.33-2.53-0.28-3.58c0.07-1.27,0.54-2.4,0.66-3.76
		c0.1-1.27,0.19-2.58,0.15-3.82c-0.03-0.93-0.03-0.96,0.17-1.83c0.06-0.22,0.42-0.49,0.5-0.79c0.05-0.19-0.3-0.49-0.26-0.72
		c0.42-2.49,1.01-4.68,0.83-7.25c-0.05-0.61-0.5-1.5-0.48-1.99c0.01-0.58,0.56-1.19,0.71-1.79c0.27-1.11,0.16-2.29,0.28-3.45
		c0.27-2.6-0.29-5.26,0.08-8.04c0.15-1.06,0.58-2.5,0.49-3.6c-0.1-1.33-0.29-2.15-0.07-3.65c0.35-2.33,0.48-4.42,0.84-6.77
		c0.03-0.24,0.5-0.52,0.58-0.79c0.07-0.25-0.3-0.59-0.25-0.87c0.12-0.65,0.24-1.2,0.33-1.86c0.17-1.21,0.23-2.45,0.29-3.65
		c0.06-1.25,0.32-2.28,0.46-3.48c0.1-0.75-0.21-2.95,0.27-3.44c-0.02-1.44,0.57-3.62,0.64-4.08c0.35-2.21,0.84-4.56,1.14-6.8
		c0.14-1.09,0-2.05,0.17-3.07c0.17-1,0.7-2.01,0.83-3.08c0.26-2.06,0.51-4.33,0.63-6.21c0.11-1.88,0.49-4.14,0.34-6.22
		c-0.17-2.4,0.67-3.66,0.76-5.89c0.09-2.29,0.54-3.96,0.86-6.12c0.12-0.85,0.35-2.16,0.39-3.1c0.01-0.47-0.32-1.23-0.29-1.56
		c0.05-0.49,0.47-0.89,0.53-1.27c0.26-1.93,0.26-4.12,0.7-6.21c0.36-1.68,1.55-4.25,0.84-6.01c1.08-1.22,1.36-5.64,1.87-7.3
		c0.36-1.18,0.39-2.66,0.61-3.94c0.19-1.09,0.77-2.19,0.87-3.23c0.01-0.01,0.12-0.02,0.12-0.03c0.02-0.12,0.14-0.24,0.14-0.36v-0.12
		c0-3.2,2.4-8.38,3.22-10.25c0.87-1.98,1.35-4.89,2.33-6.55c1.08-1.84,3.1-2.51,4.46-4.32c0.79-1.05,1.01-1.79,1.89-2.79
		c0.72-0.85,1.9-1.35,2.58-2.26c-0.13-0.49-0.01-1.06,0-1.48c1.98,0.42,2.27-2.28,3.14-3.53c0.46-0.67,1.18-1.19,1.71-1.66
		c0.57-0.51,1.73-1.12,2.14-1.5c1.18-1.09,2.51-2.34,3.57-3.34c0.35-0.33,1.55-1.68,1.76-1.96c0.09-0.13,0.87-0.2,1.09-0.4
		c0.36-0.34-0.17-1.21,0.09-1.48c1.31-1.38,3.08-2.64,5-1.29c-0.42-0.47-0.55-1.23-0.89-1.71c0.02-0.01,0.49-0.19,0.55-0.19
		c-0.47-1.46,0.04-3.72,0.53-4.97c0.53-1.33,1.89-2.86,2.46-4.16c0.22-0.51,0.74-2.14,0.88-2.64c0.1-0.36,0.98-0.41,1.12-0.73
		c0.2-0.43-0.55-1.09-0.53-1.14c0.76-1.35,1.61-2.68,2.55-3.99c-0.57-1.18,0.14-2.13,1.33-2.66c-1.35-1.49,0.05-1.43,0.37-2.47
		c0.19-0.6,0.89-1.83,1.23-2.56c0.64-1.34,1.46-4.06,2.9-4.81c-0.21-0.46-0.25-1.29-0.45-1.68c0.22-0.21,0.51-0.14,0.75-0.3
		c-0.55-1.2-0.65-1.3,0.05-2.23c-0.3-0.71-0.35-1.08-0.55-1.78c-2.06-0.85-10.95-8.93-11.95-19.18c0,0-0.64-2.47,2.84-0.23
		c0.55-1.23,2.12-1.05,3.12-1.16c0.59-0.06,1.29,0.48,1.8,0.4c0.71-0.1,1.04-1,1.83-1.17c2.43-0.53,4.97,0.58,7.59-0.49
		c0.06,0.4,0.42,0.9,0.47,1.3c1.72-0.64,3.04,0.14,4.71,0.04c0.02,1.9,3.69,1.62,3.83,3.82c1.31-0.07,2.52,0.45,2.34,1.96
		c1.66,0.15,1.59,1.59,3.12,2.22c-0.62,1.27,2.61,7.43,3.84,7.46c-0.07,0.58,0.26,1.35,0.17,1.84c0.27-0.14,0.58-0.33,0.84-0.47
		c0.72,3.07,0.32,6.3,0.42,9.15C238.34,160.69,236.88,165.07,238.27,167.04z"/>
					<path fill="#D7C5A1" d="M167.76,233.51v0.08c0-0.03,0-0.06-0.01-0.09L167.76,233.51z" />
					<path fill="#16110E" d="M77.146,236.377c-0.217,2.357-0.633,4.252-1.943,6.258c-1.23,1.885-2.876,3.544-3.374,5.816
		c-0.932,4.255,3.3,7.834,6.028,10.503c1.408,1.378,3.024,2.756,5.018,1.71c1.997-1.047,3.029-3.266,5.232-4.106
		c2.436-0.93,4.956-0.013,7.444-0.365c2.547-0.36,3.456-2.208,4.257-4.436c1.548-4.309,4.921-8.002,3.857-12.753
		c-0.144-0.641-0.328-1.275-0.509-1.907c-3.333-0.683-6.694-1.758-10.085-2.05c-3.546-0.306-6.978,0.298-10.476,0.722
		c-1.889,0.229-2.939,0.206-4.814,0.417L77.146,236.377z"/>
					<path fill="#7C7C7C" d="M82.679,252.938c-1.343,1-2.999,0.439-2.976-1.125c0.02-1.351,1.123-3.336,2.128-4.146
		c3.309-2.667,4.622-0.486,3.403,2.328C84.609,251.438,82.679,252.938,82.679,252.938z"/>
					<g>
						<ellipse fill="#6B6B6B" cx="99.565" cy="367.522" rx="1.477" ry="1.483" />
						<ellipse fill="#7C7C7C" cx="99.544" cy="367.544" rx="0.831" ry="0.836" />
					</g>
					<g>
						<ellipse fill="#6B6B6B" cx="96.565" cy="425.522" rx="1.477" ry="1.483" />
						<ellipse fill="#7C7C7C" cx="96.544" cy="425.544" rx="0.831" ry="0.836" />
					</g>
					<g>
						<ellipse fill="#6B6B6B" cx="95.565" cy="478.521" rx="1.477" ry="1.484" />
						<ellipse fill="#7C7C7C" cx="95.544" cy="478.545" rx="0.831" ry="0.836" />
					</g>
					<g>
						<ellipse fill="#6B6B6B" cx="100.565" cy="309.521" rx="1.477" ry="1.484" />
						<ellipse fill="#7C7C7C" cx="100.544" cy="309.545" rx="0.831" ry="0.836" />
					</g>
					<circle fill="#7C7C7C" cx="176.167" cy="263.667" r="4.5" />
					<circle fill="#7C7C7C" cx="222.167" cy="268.667" r="4.5" />
					<circle fill="#7C7C7C" cx="168.167" cy="323.667" r="4.5" />
					<circle fill="#7C7C7C" cx="163.167" cy="383.667" r="4.5" />
					<circle fill="#7C7C7C" cx="158.167" cy="443.666" r="4.5" />
					<circle fill="#7C7C7C" cx="153.167" cy="499.666" r="4.5" />
					<path fill="#16110E" d="M319.31,377.33c-0.34,7.24-0.32,16.11-4.06,26.92c0.53,4.36-1.33,11.01-1.29,15.31
		c0.06,7.34-3.62,12.74-5.04,19.67c-1.33,6.489,1.94,13.67-1.32,19.71c-3.73,6.89-8.1,8.939-7.58,17.739
		c0.47,8.101,0.5,14.07-1.55,21.96c-1.78,6.801-1.64,13.92-3.86,20.62c-2.21,6.7-4.61,11.95-4.15,19.2
		c-1.32,0.72-2.8,1.54-4.27,1.89c-6.19,1.511-10.61-3.26-15.6-6.21c-5.37-3.189-10-5.409-16.35-5.489
		c-5.95-0.07-14.29,2.54-11.4-5.23c2.09-5.6,1.75-11.52,4.04-16.93c1.13-2.65,2.6-4.34,2.64-7.351c0.04-3.04-1.6-5.75-0.98-8.88
		c1.3-6.58,6.04-8.16,5.26-15.42c-0.68-6.27,0.78-11.649-0.98-17.84c-1.07-3.76-1.66-6.51-0.45-10.35c1-3.16,3.38-5.891,3.19-9.36
		c-0.17-2.94-2.41-5.3-2.85-8.19c-0.46-3.08,1.03-5.7,1.11-8.7c0.02-0.83-0.03-1.62-0.15-2.37c-0.32-2.21-1.11-4.11-1.61-6
		c-0.15-0.79-0.26-1.59-0.34-2.41c-0.04-0.38-0.07-0.77-0.1-1.17c-1.5-19.07,5.12-65.84-0.62-83.2c-3-9.08-4.5-15.19-1-33
		c3.3-16.81,7.93-21.14,13.52-37.28c5.62-16.22,12.31-27.47,22.29-45.93c1.9-3.51,8.16-8.1,9.49-6.71
		c7.25,15.18,10.65,28.43,12.93,45.18c2.43,17.82,7.72,55.11,10.18,72.93c2.5,18.04-0.87,15.84-2.28,33.93
		C314.79,371.58,320.11,360.2,319.31,377.33z"/>
					<path fill="#16110E" d="M253.67,418.03c-0.71-1.89-1.26-3.9-1.61-6C252.56,413.92,253.35,415.82,253.67,418.03z" />
					<path fill="#16110E" d="M252.06,412.03c-0.32-1.17-0.52-2.34-0.44-3.58c0.03,0.4,0.06,0.79,0.1,1.17
		C251.8,410.44,251.91,411.24,252.06,412.03z"/>
				</g>
			</svg>
		</div>
	)
}



export default Dennis