import React from 'react'

import "./WasteViz.scss";

const Waste = () => {

	return (
		<div className='waste-wrapper'>

			<svg viewBox="0 0 612 792">

				<g id="Ebene_2">
					<path fill="#2B303D" d="M172.791,694.431l-20.455-425.137l205.313,6.465c0,0-4.475,315.096-15.948,430.74
		C339.834,725.322,172.791,694.431,172.791,694.431z"/>
					<path fill="#A4AB7B" d="M354.134,149.437c-3.377-0.853-2.745-3.804-3.827-6.632c-0.709-1.856-2.566-3.975-2.427-6.035
		c0.196-2.896,5.243-8.191,7.548-9.972c1.807-1.398,3.379-1.361,4.477-3.649c0.904-1.883,0.919-3.969,1.934-5.822
		c1.133-2.064,2.491-2.91,4.795-2.205c2.358,0.722,2.414,1.926,3.024,4.142c0.304,1.104,0.96,4.257,2.134,4.805
		c2.063,0.966,3.708-3.17,5.575-2.232c1.298,0.651-0.728,3.561,0.637,5.182c1.412,1.678,3.5,0.967,5.62,0.663
		c1.828-0.261,3.998-0.89,5.854-0.227c1.71,0.609,2.052,2.1,2.587,3.879c1.029,3.422,1.322,1.621,4.322,2.575
		c2.543,0.808,1.333,2.877,1.234,4.973c-0.09,1.902,1.328,3.23,1.397,4.952c0.083,2.046-3.286,2.869-5.015,3.879
		c-2.703,1.578-5.404,3.114-7.763,5.171c-1.263,1.101-2.678,3.854-4.079,4.537c-1.725,0.841-5.014-1.552-6.535-2.327
		c-1.84-0.937-3.897-2.487-5.965-2.867c-2.268-0.417-4.67,0.34-6.951,0.227c-2.58-0.129-4.31-1.298-6.637-2.371"/>
					<path fill="#7B7B70" d="M317.064,126.161c-3.705,3.305-8.964,6.099-13.312,8.458c-4.079,2.211-4.163,0.435-4.587-3.511
		c-0.438-4.078-2.384-2.156-5.759-1.762c-0.75,0.087-3.265,0.428-3.731-0.416c-0.81-1.461,3.47-5.067,4.329-6.006
		c1.559-1.706,3.782-3.381,4.111-5.815c0.358-2.641-1.483-5.328-2.611-7.558c-2.884-5.7-1.59-9.716,3.673-13.845
		c1.717-1.348,3.744-2.633,6.034-2.366c2.027,0.236,2.959,1.829,4.576,2.821c2.911,1.786,6.938,2.501,10.128,3.87
		c3.794,1.628,8.465,4.153,8.156,8.858c-0.181,2.761-3.325,7.153-2.604,9.739c0.449,1.621,1.822,1.453,3.225,1.924
		c4.014,1.346,1.52,2.944-1.277,3.939c-3.619,1.289-7.451,2.684-11.23,3.216c-0.196-0.08-0.262-0.235-0.195-0.468"/>
					<path fill="#799EBE" d="M288.244,69.289c-1.596-2.164,3.376-0.879,4.49-0.63c1.758,0.392,3.375,0.992,5.096,1.541
		c3.984,1.27,7.589,2.648,11.004,4.958c6.354,4.297,12.744,8.69,18.547,13.732c2.762,2.399,5.635,4.332,7.923,7.098
		c2.745,3.316,5.491,3.569,9.47,3.947c6.129,0.582,15.584,6.539,15.787,13.485c0.106,3.634,0.134,7.523,4.594,6.572
		c0.406,1.724-0.309,1.744,0.727,3.108c0.662,0.873,1.981,1.403,2.761,2.205c2.027,2.085,0.759,3.647-0.647,6.058
		c-1.012,1.735-2.742,4.557-5.02,4.185c-1.252-0.204-2.207-2.129-3.369-2.223c-1.308-0.106-1.198,1.376-2.508,1.865
		c-0.908-1.741,1.412-3.648-0.691-4.966c-0.575-0.361-2.27-0.188-2.971-0.296c-1.319-0.205-2.592-0.739-3.911-0.982
		c-4.589-0.845-9.327-0.421-13.985-1.242c-2.295-0.405-4.53-0.847-6.783-1.124c-2.1-0.259-5.055,0.257-6.98-0.58
		c-1.683-0.73-2.525-2.797-3.767-3.806c-1.522-1.238-2.708-2.223-3.714-3.977c-1.883-3.282-4.757-4.729-8.149-6.479
		c-3.179-1.639-5.757-4.042-8.494-6.285c-3.079-2.522-5.978-4.439-9.756-5.7c-1.839-0.614-3.587-1.213-5.172-2.347
		c-2.706-1.935-3.573-4.368-6.947-5.711L288.244,69.289z"/>
					<path fill="#D5DDE9" d="M301.333,161.506c-5.957,2.538-12.121-4.911-12.068-10.392c0.035-3.674-0.155-9.294,1.24-12.669
		c1.711-4.141,5.192-7.945,8.189-11.2c1.285-1.395,2.472-3.077,3.937-4.321c1.445-1.229,3.255-1.926,3.664-3.88
		c0.209-0.999-0.477-0.4-0.915-1.264c-1.782,0.667-2.81,0.097-3.999,1.497c-0.872,1.026-3.608,3.495-2.261,0.366
		c0.643-1.494,3.421-2.536,4.59-3.66c1.903-1.831,2.036-2.098,4.736-1.185c1.848,0.625,3.513,1.829,4.788,0.167
		c1.11-1.446,1.265-4.14,2.161-5.83c0.863-1.625,5.759-9.83,8.136-9.282c0.282,0.065,0.37,0.102,0.625,0.215
		c-1.015,3.286-3.663,7.373-5.802,10.15c-1.031,1.339-3.305,3.151-3.442,4.951c-0.167,2.203,2.772,0.859,4.573,0.599
		c4.363-0.63,8.217,0,12.607,0.047c3.783,0.041,8.709,0.385,11.32,3.605c1.369,1.689,1.111,3.462,3.083,4.644
		c1.724,1.033,3.78,1.409,5.489,2.586c1.818,1.253,2.779,2.052,2.999,4.256c0.216,2.149-0.241,4.586,0.007,6.839
		c0.473,4.299,3.156,7.395,1.568,11.901c-1.203,3.407-3.886,6.006-5.441,9.225c-1.354,2.802-0.891,4.862-1.293,7.758
		c-0.594,4.262-6.891,6.928-10.609,8.237c-4.917,1.73-10.594,1.322-15.684,0.857c-4.683-0.429-8.979-1.862-12.942-4.299
		c-3.271-2.009-6.164-2.06-9.687-3.454"/>
					<path fill="#BDD6BE" d="M200.544,112.8c-0.008-12.271,4.664-30.472,17.824-34.848c2.754-0.915,5.481-0.458,7.39-3.091
		c1.679-2.313,1.26-5.382,2.076-8.037c2.053-6.681,7.446-6.873,13.227-8.613c2.757-0.83,5.229-1.283,8.03-1.731
		c2.979-0.476,4.818-1.864,7.486-3.082c5.794-2.646,11.728-2.195,16.953,1.148c4.667,2.985,12.753,4.336,10.345-3.821
		c-1.338-4.533-5.576-10.699,0.288-14.152c6.68-3.935,4.316,8.865,4.008,12.069c-0.292,3.023,0.276,4.485,1.101,7.263
		c1.03,3.466,0.871,3.204,4.022,4.958c2.202,1.225,3.828,3.193,6.036,4.316c2.694,1.369,5.473,1.176,8.461,1.358
		c5.75,0.35,10.92,2.149,15.739,5.388c2.178,1.464,4.146,4.151,0.512,5.609c-2.499,1.002-6.626-1.703-9.132-2.369
		c-5.616-1.491-11.214-2.77-16.701-4.943c0.087,1.966,3.333,4.974,4.417,6.954c1.181,2.159,2.807,3.708,4.453,5.524
		c3.793,4.184,5.51,8.644,4.654,14.318c-0.881,5.831-3.914,8.068-6.897,12.615c-2.553,3.892-2.908,9.892-4.007,14.374
		c-1.447,5.9-4.49,8.178-7.775,12.932c-3.219,4.658,1.993,10.582-2.858,14.646c-4.112,3.444-12.815,3.352-17.895,4.096
		c-8.06,1.181-15.389,3.055-23.547,1.309c-5.324-1.14-10.448-3.376-15.869-3.671c-5.648-0.308-8.605-2.196-12.228-6.546
		c-2.73-3.277-4.876-5.895-7.097-9.553c-1.312-2.159-4.517-10.102-6.983-10.05L200.544,112.8z"/>
					<path fill="#C5DDF2" d="M182.153,124.006c-0.53-3.366,3.609-12.875,7.22-13.457c-2.963-2.179-2.835-6.4-0.461-8.956
		c-2.418-2.932-0.146-7.661,3.887-6.963c-4.265-7.094,5.535-6.774,9.612-5.871c5.066,1.124,10.344,2.826,15.108,5.073
		c4.064,1.918,26.238,15.313,11.324,16.853c1.054,4.706,0.232,7.218-3.006,10.304c2.819,4.021-0.32,8.299-4.386,9.536
		c-0.08,1.268,0.365,3.489-0.053,4.688c-0.658,1.894-2.357,2.2-3.047,3.438c-0.703,1.262-0.446,3.292-1.395,4.762
		c-1.106,1.714-3.091,1.216-4.211,2.47c-1.924,2.156-0.864,5.901-3.462,8.208c-2.317,2.058-7.317,2.229-10.227,1.715
		c-7.247-1.279-11.339-7.73-17.325-11.014c-4.374-2.399-4.832-1.28-3.89-7.006c0.515-3.125,1.606-6.886,1.293-9.9"/>
					<g>
						<polygon fill="#3D4149" points="105.379,233.928 167.35,201.299 176.209,211.996 119.239,243.249 		" />
						<path fill="#202A3B" d="M111.656,234.21c-13.988-3.996-10.279,11.804-6.309,20.565c4.079,9,10.169,15.581,17.536,22.133
			c7.865,6.995,18.089,9.451,27.873,12.357c3.591,1.066,4.242,1.022,4.598-2.81c0.265-2.867,0.287-5.697,0.287-8.621
			c0-5.922,0.73-12.345,0.016-18.159c-0.444-3.607-1.417-10.824-5.181-12.157c-4.252-1.504-11.072,1.068-15.587,1.229
			c-4.612,0.164-9.782-1.32-13.729-3.809C117.138,242.406,118.913,236.283,111.656,234.21z"/>
					</g>
					<path fill="#9FB0CD" d="M163.771,233.183c-10.551,5.901-10.438-12.822-13.029-17.232c-1.936-3.294-4.283-7.354-7.208-8.725
		c-5.941-2.785-4.259-1.372-5.092-7.665c-0.461-3.482-4.833-6.909-4.756-10.775c0.032-1.625,2.08-3.28,1.895-4.837
		c-0.257-2.159-3.062-2.937-3.282-5.077c-0.286-2.759,12.562-14.129,13.459-16.863c1.644-5.013-7.847-8.45-4.964-12.646
		c19.352-28.164,28.222-4.066,40.938-4.572c4.041-0.161,4.813-7.189,8.33-5.047c3.399,2.069,4.829,6.132,7.308,8.956
		c5.616,6.392,14.978,7.706,19.847,14.857c3.392,4.979,3.136,10.439,2.6,16.071c-0.513,5.367,0.449,11.24-0.421,16.391
		c-1.046,6.19-5.119,10.446-8.211,15.601c-3.895,6.491-4.868,13.449-6.034,20.7"/>
					<path fill="#E4D3D3" d="M189.696,174.005c-0.38-2.48-1.126-4.839-1.46-7.29c-0.351-2.571,1.066-3.002,3.352-4.143
		c0.806-0.402,1.917-0.547,2.634-1.125c1.173-0.945,0.722-1.142,1.293-2.539c0.729-1.78,2.381-3.095,4.095-3.82
		c1.421-0.602,4.591-0.651,5.234-2.419c0.704-1.932-2.566-3.315-1.743-4.907c0.469-0.907,4.184-2.419,5.13-3.072
		c2.524-1.744,4.858-3.772,7.325-5.597c5.661-4.188,10.785-9.954,17.257-12.856c2.783,7.013,9.486,12.446,17.49,12.424
		c2.554-0.007,3.257,0.091,5.065,1.784c1.412,1.321,2.854,2.582,4.316,3.879c3.706,3.286,7.875,4.303,9.278,9.794
		c1.317,5.158,1.854,10.802,2.892,16.008c0.185,0.925,0.601,1.888,0.599,2.845c-0.004,2.005-0.012,1.578-1.936,2.059
		c-4.411,1.103-7.493,2.922-11.054,5.709c-1.807,1.414-3.373,3.134-5.21,4.467c-3.389,2.457-8.394,3.055-12.463,3.507
		c-4.131,0.458-7.72-0.433-11.531-1.987c-3.783-1.542-7.471-2.875-11.421-4.106c-5.84-1.822-11.403-4.423-17.301-5.975
		c-3.791-0.997-7.7-1.294-11.411-2.208"/>
					<path fill="#E9F3FB" d="M247.844,127.808c-0.243-1.311,0.016-2.539,0.213-3.843c0.102-0.682,0.267-1.118-0.054-1.713
		c-0.315-0.585-1.172-0.152-1.06-1.154c0.151-1.361,5.275-2.62,6.403-3.175c2.23-1.099,4.572-1.971,6.733-3.121
		c2.011-1.069,4.17-2.443,6.028-3.791c0.815-0.591,1.731-1.758,2.683-2.066c1.607-0.521,1.587,0.849,0.855,1.965
		c0.922,0.591,2.359,0.325,3.469,0.931c1.088,0.595,2.115,1.627,2.798,2.671c1.399,2.141,2.174,7.942,3.869,9.926
		c3.57,4.177,5.231,4.798,7.222,9.806c0.524,1.321,1.518,6.216,2.045,7.543c0.508,1.278,2.053,0.573,2.286,1.9
		c0.634,3.603,4.795,13.563,1.962,14.493c-3.734,1.229-22.193,23.985-23.078,20.043c-3.322-14.798-11.872-26.829-12.652-27.443
		C256.827,150.196,248.338,130.463,247.844,127.808z"/>
					<g>
						<path fill="#79ACD3" d="M317.824,140.275c0,0-2.422,0.261-3.354,0.292c-4.869,0.163-16.248,1.837-16.739,1.939
			c1.32,3.591,3.872,6.931,5.672,10.318c3.817-0.212,20.522-3.461,20.522-3.461L317.824,140.275z"/>
						<polygon fill="#4882C1" points="303.404,152.825 278.764,183.742 270.603,172.357 297.731,142.506 		" />
						<polygon fill="#9ABED8" points="323.926,149.364 299.416,180.74 278.764,183.742 303.404,152.825 		" />
					</g>
					<path fill="#E9F3FB" d="M387.323,144.696c2.589-0.657,5.193-1.425,7.896-1.435c1.332-0.005,2.494,0.471,3.742,0.897
		c0.652,0.222,1.053,0.462,1.695,0.253c0.631-0.205,0.358-1.125,1.324-0.836c1.313,0.392,1.633,5.659,1.978,6.868
		c0.682,2.392,1.119,4.852,1.864,7.184c0.692,2.17,1.656,4.54,2.65,6.609c0.435,0.908,1.419,2.018,1.551,3.01
		c0.227,1.675-1.118,1.409-2.086,0.489c-0.746,0.801-0.742,2.264-1.537,3.246c-0.781,0.965-1.979,1.79-3.13,2.275
		c-2.356,0.993-4.501,1.916-6.756,3.229c-4.749,2.765-10.923,3.899-16.207,4.961c-1.394,0.279-2.796,0.469-4.194,0.75
		c-1.35,0.271-2.959,1.017-4.308,1.009c-3.657-0.021-4.483-3.226-4.892-6.18c-0.54-3.894-1.088-8.663-0.285-12.533
		c0.852-4.105,5.324-8.204,8.346-10.887c1.84-1.633,3.824-2.592,6.02-3.593c0.888-0.404,1.598-0.743,2.343-1.4
		c0.706-0.624,1.538-2.174,2.262-2.333"/>
					<path fill="#E1E683" d="M372.021,195.772c0.574-3.81,2.455-7.96,4.246-11.255c1.924-3.543,2.985-7.426,3.722-11.373
		c0.36-1.927-0.58-4.1,1.085-5.172c1.979-1.274,3.828,0.026,4.74-2.419c1.277-3.426,1.607-8.135,2.156-11.816
		c0.473-3.186,0.16-6.236,1.992-9.046c1.462-2.245,3.798-4.282,4.88-6.691c-4.014-1.293-8.861,1.626-13.172,0.673
		c-2.217-0.49-3.085-2.384-4.966-0.919c-0.77,0.598-0.492,1.5-1.453,1.938c-0.86,0.392-2.693,0.014-3.613,0.053
		c-4.77,0.205-9.608,0.44-14.481,0.426c-2.5-0.007-5.13,0.101-7.603,0.432c-2.447,0.328-2.721,1.711-4.521,3.184
		c-1.357,1.111-3.819,1.752-4.861,3.119c-1.354,1.78,1.021,3.018,1.671,5.161c0.654,2.161,0.424,4.4,1.137,6.59
		c0.988,3.022,3.292,5.592,4.902,8.297c5.049,8.464,4.752,19.293,10.729,27.263c2.045,2.726,3.115,5.528,4.397,8.641
		c3.204-0.273,3.928-5.613,7.288-5.791"/>
					<g>
						<polygon fill="#D5BC3B" points="328.703,144.839 340.583,144.839 366.205,175.522 352.41,179.178 		" />
						<polygon fill="#C6AB38" points="352.41,179.178 353.847,208.776 368.646,208.776 366.205,175.522 		" />
						<polygon fill="#BCA43F" points="328.703,144.839 328.703,180.579 353.847,208.776 352.41,179.178 		" />
					</g>
					<path fill="#763B3C" d="M291.204,214.091c-0.171-0.751-0.728-3.67,0.259-4.324c0.845-0.561,2.578,0.725,3.572,0.881
		c2.741,0.43,5.314-1.381,7.543-2.854c4.247-2.808,10.273-6.322,9.961-11.972c-0.133-2.414-1.424-3.895-1.998-6.074
		c-0.553-2.102,0.901-3.43-0.642-5.338c-2.832-3.501-9.628-1.951-13.587-3.346c-2.281-0.803-3.966-1.216-5.587-2.7
		c-0.998-0.915-0.585-0.891-1.834-1.343c-1.035-0.374-2.418-0.28-3.506-0.592c-2.177-0.624-3.501-1.629-4.897-3.286
		c-1.658-1.966-2.587-2.475-5.244-3.012c-3.146-0.636-11.528-3.885-13.181-0.657c-1.167,2.277-0.139,3.342-2.96,4.531
		c-3.038,1.278-6.288,2.64-9.492,3.446c-3.012,0.759-3.501,0.204-3.46,3.401c0.033,2.608-1.621,2.906-4.304,1.934
		c-0.903-0.327-1.86-0.713-2.534-1.456c-0.682-0.751-0.765-2.17-1.756-2.557c-1.603-0.624-6.587,1.98-7.775,2.989
		c-0.763,0.649-2.223,3.79-1.944,5.173c0.351,1.743,3.726,1.816,2.973,3.609c-0.742,1.767-4.336,1.404-5.781,1.996
		c-1.391,0.568-4.459,2.489-4.788,4.088c-0.473,2.295,5.328,7.001,7.164,7.758c2.016,0.829,3.997,0.444,5.23,2.581
		c0.974,1.685,0.915,3.809,1.724,5.615c2.227,4.965,5.363,4.424,10.124,4.956c1.816,0.203,3.493,0.559,5.113,1.354
		c1.845,0.907,3.543,2.665,5.622,3.13c6.844,1.53,12.772-5.448,19.438-4.488c5.905,0.849,10.414-0.469,15.685-3.228"/>
					<g>
						<path fill="#4882C1" d="M287.749,181.659c0,0,2.121,1.199,2.897,1.717c4.051,2.704,14.276,7.971,14.734,8.176
			c1.02-3.688,0.892-7.889,1.401-11.692c-3.227-2.051-18.697-9.142-18.697-9.142L287.749,181.659z"/>
						<polygon fill="#9ABED8" points="306.781,179.859 344.818,169.083 344.818,183.092 305.38,191.551 		" />
						<polygon fill="#79ACD3" points="288.084,170.718 326.285,159.493 344.818,169.083 306.781,179.859 		" />
					</g>
					<path fill="#7F7278" d="M210.601,247.927c-4.423-1.386-8.394-4.104-10.722-7.98c-2.042-3.401-1.882-7.542-3.879-10.996
		c-1.984-3.434-3.652-6.313-4.748-10.118c-1.255-4.359-3.833-5.692-6.729-8.836c-3.674-3.991-0.756-6.812,0.592-10.991
		c0.634-1.96-0.285-3.923,1.144-5.555c1.254-1.433,3.446-1.745,5.161-1.988c2.431-0.345,4.066,0.338,6.417,0.657
		c2.423,0.33,4.782,0.009,7.208-0.011c4.913-0.042,9.399-1.689,14.212-2.359c2.481-0.345,4.857-0.894,5.735,1.713
		c0.688,2.042,0.522,4.37,1.389,6.465c0.693,1.675,1.755,3.602,3.401,4.537c1.801,1.024,4.147,0.092,5.938,0.894
		c3.791,1.7,2.881,6.772,4.605,9.487c2.301,3.623,8.175,2.54,11.611,3.401c3.635,0.913,7.534,4.069,6.713,8.195
		c-1.03,5.177-8.522,8.943-12.273,12.5c-1.728,1.638-3.118,3.807-4.952,5.221c-2.433,1.877-6.512,2.927-9.537,3.19
		c-5.845,0.508-11.139-1.075-16.761,0.845c-0.968,0.331-1.998,0.932-3.018,1.035c-1.273,0.127-2.454-0.626-3.664-0.167"/>
					<path fill="#EDEFF3" d="M149.61,188.23c-1.444,0.147-2.775-0.164-3.852-1.11c0.898-2.675,2.808-4.868,3.689-7.679
		c0.846-2.698,1.328-6.249,0.395-9.056c4.578-1.571,7.722-0.484,12.256,0.22c2.422,0.376,4.859,0.504,7.345,0.598
		c2.307,0.088,4.627,0.962,6.891,0.862c4.006-0.176,7.269-3.285,10.992-4.525c3.667-1.222,7.364,1.217,11.028,1.933
		c6.936,1.354,13.344,3.062,20.604,2.975c6.896-0.083,13.456,1.085,20.01,3.146c-2.184,7.16,1.288,14.85,0.915,21.874
		c-2.226-0.172-3.82-2.035-5.791-2.779c-1.781-0.672-3.892-0.798-5.771-1.084c-4.634-0.706-10.428-1.095-15.138-0.634
		c-1.863,0.183-3.817,0.748-5.599,1.292c-2.62,0.801-3.397,0.754-5.819-0.269c-3.74-1.58-8.5-1.566-12.505-2.27
		c-7.601-1.335-15.262-1.709-22.85-2.845c-6.583-0.985-7.791-1.154-14.627-1.154L149.61,188.23z"/>
					<path fill="#A78C70" d="M281.721,253.746c-1.182-2.455-4.35-0.976-6.465-0.862c-2.912,0.157-5.777-0.329-8.357-1.725
		c-4.477-2.421-8.375-5.88-12.764-8.415c-1.67-0.966-5.086-0.836-6.276-2.361c-1.549-1.985,0.627-5.557,1.481-7.38
		c1.67-3.57,2.905-7.172,3.879-10.938c0.263-1.015,0.651-1.811,0.274-2.812c-0.385-1.021-1.697-1.44-1.901-2.571
		c-0.262-1.458,1.512-4.009,2.753-4.697c2.065-1.147,3.615-0.487,5.388-2.42c1.442-1.573,2.485-3.43,3.938-4.903
		c2.314-2.346,7.522-4.158,10.711-2.913c3.626,1.415,5.966,5.116,7.386,8.463c1.644,3.876,2.266,7.123,5.986,9.321
		c4.208,2.486,9.055,4.665,8.626,10.453c-0.171,2.304-1.472,4.316-0.705,6.518c0.574,1.648,2.182,3.268,3.124,4.742
		c2.073,3.244,2.612,7.408-0.221,10.351c-2.389,2.481-6.235,1.801-9.369,1.335c-3.035-0.451-7.353-0.373-10.076,1.245"/>
					<g>
						<path fill="#444753" d="M152.557,242.686c-1.479-7.267-0.961-14.237,2.649-20.674c3.193-5.691,10.222-10.61,10.389-17.443
			c6.664,1.402,13.13-0.319,18.942-3.096c1.176,10.199,11.954,16.415,14.887,25.981c1.213,3.956,2.623,10.086,1.029,14.171
			c-2.082,5.333-11.7,7.678-16.854,7.958c-6.429,0.351-13.011-0.247-19.333-0.861c-5.406-0.526-9.25-2.705-11.423-8.046"/>
						<path fill="#CED9DE" d="M151.608,235.008c3.905,2.001,5.227,2.093,9.855,2.934c6.322,1.149,9.571,0.973,14.143,0.721
			c5.435-0.3,21.817-3.082,24.474-8.867c2.385,18.347,5.387,30.039-1.55,31.784c-7.084,1.784-34.481,6.103-45.257-3.093
			C153.274,258.487,151.782,249.686,151.608,235.008z"/>
						<path fill="#CED9DE" d="M165.487,205.04c-0.962-0.225-1.244-0.259-1.293-1.295c-0.033-0.673-0.037-1.326-0.071-2.007
			c-0.075-1.464-0.144-3.009-0.356-4.452c-0.193-1.323-0.5-2.69-0.434-4.043c0.075-1.533,0.075-2.392,1.291-3.416
			c2.25-1.894,5.139-2.255,7.905-2.659c2.995-0.436,6.212-0.412,9.123,0.505c1.226,0.386,2.294,0.975,3.32,1.76
			c0.255,0.195,0.541,0.328,0.65,0.664c0.151,0.47,0.879,12.103,0.2,12.517c-3.74,2.282-10.643,2.492-13.653,2.641
			c-2.196,0.109-5.14-0.359-7.327-0.359"/>
					</g>
					<g>
						<path fill="#24161D" d="M221.233,259.637c8.275-11.236,9.181-25.416,13.499-38.067c2.443,5.855,8.031,9.28,14.081,10.2
			c3.192,0.486,6.078,1.07,9.194,1.725c3.303,0.692,6.568-1.723,9.459-0.897c-1.654,4.583-4.156,9.861-4.917,14.683
			c-0.387,2.451-0.135,5.074-0.791,7.472c-0.568,2.074-0.523,5.334-2.014,6.537c-3.689,2.979-13.039,2.261-17.233,1.221
			c-2.748-0.68-5.092-2.097-7.781-2.873c-2.942-0.849-5.396-0.262-8.311,0c-1.5,0.135-3.787,0.249-4.899-0.575"/>

						<ellipse transform="matrix(0.9636 0.2672 -0.2672 0.9636 69.7113 -58.8473)" fill="#E6EBF1" cx="251.099" cy="226.741" rx="17.261" ry="6.715" />

						<ellipse transform="matrix(0.9636 0.2672 -0.2672 0.9636 69.7099 -58.8464)" fill="#CACBD6" cx="251.099" cy="226.741" rx="14.072" ry="5.474" />
					</g>
					<path fill="#8C818E" d="M284.307,214.077c1.995-0.576,4.028-3.311,5.5-4.854c2-2.098,3.926-4.286,5.976-6.29
		c3.271-3.198,6.411-6.464,9.678-9.665c6.785-6.649,14.427-12.589,20.921-19.435c2.391-2.52,4.656-3.17,7.736-1.386
		c2.234,1.295,4.41,3.069,6.497,4.646c4.724,3.565,9.257,8.062,13.619,12.25c4.806,4.617,9.372,9.351,13.813,14.365
		c2.009,2.266,4.186,4.33,3.018,7.788c-1.445,4.287-3.938,8.501-6.545,12.004c-3.178,4.271-6.865,8.295-10.48,12.042
		c-2.676,2.773-4.746,6.009-7.303,8.859c-2.444,2.723-5.625,4.292-8.424,6.531c-1.026,0.82-2.303,2.24-3.627,2.374
		c-1.41,0.14-3.301-1.123-4.748-1.427c-3.721-0.779-7.856-0.995-11.666-0.989c-1.344,0.002-2.895,0.888-4.129,0.692
		c-1.322-0.211-2.335-1.852-3.246-2.762c-5.573-5.567-12.002-10.417-17.597-16.298c-4.685-4.927-13.066-10.31-9.147-18.617"/>
					<path fill="#D28EAA" d="M390.557,194.911c-0.841,2.408,0.899,3.529,2.586,4.521c1.686,0.992,3.029,2.785,4.945,3.41
		c2.025,0.662,4.41,0.132,6.461,0.043c2.627-0.114,1.909,10.315,4.284,10.949c4.281,1.143,9.813-7.827,5-9.872
		c-2.245-0.954-3.131-5.357-5.167-5.598c-1.902-0.226-2.693-2.875-2.855-4.521c-0.111-1.129,0.277-2.155-0.422-3.249
		c-0.761-1.189-1.833-0.752-1.912-2.533c-0.09-2.033-6.115-9.887-8.634-9.381c0.116-1.494-8.715-3.951-10.676-4.348
		c-1.951-0.396-4.856-3.217-5.733-5.63c-1.146-3.15-4.154-4.037-7.102-3.501c0.078,0.113,0.229,0.418,0.401,0.616
		c-0.714,1.574-1.898,3.626-1.383,5.166c0.444,1.328-1.645,3.768-0.652,4.538c1.973,1.53-1.374,8.093-5.024,11.628
		c-0.898,0.869-7.041,6.775-7.024,8.006c0.035,2.61,11.218,2.192,12.647,1.911c2.838-0.559,0.451-1.49,3.391-1.494
		c1.231-0.001-2.881-4.304-1.877-3.646c1.164,0.762,0.881,2.849,1.877,3.646c2.104,1.684,16.075-3.57,16.222-0.015"/>
					<path fill="#3D4149" d="M341.124,708.017c0.345-0.166,34.342-44.906,34.735-59.202c1.973-71.407,27.91-412.71,27.91-412.71
		l-46.12,39.654L341.124,708.017z"/>
					<path fill="#222730" d="M151.782,234.396c-4.68,2.5-18.098,13.882-20.056,18.087c-1.214,2.608-0.965,13.304-0.224,15.804
		c1.274,4.296,7.486,4.951,11.494,5.46c7.201,0.917,124.402,11.816,135.695,12.356c11.105,0.531,22.656,1.239,33.269,4.302
		c10.351,2.988,20.378,6.842,30.747,9.787c11.089,3.149,21.166,4.589,32.772,5.164c5.575,0.276,11.026-0.285,16.379-2.299
		c4.812-1.811,45.66-48.383,49.906-55.631c1.278-2.182,1.411-5.425,1.291-7.873c-0.161-3.301-0.521-3.956-3.498-5.157
		c-3.419-1.38-4.07-1.15-4.051-5.195c0.008-1.818-0.095-4.699-1.135-6.458c-2.559-4.318-11.771-2.538-15.805-2.873
		c-5.042-0.419-8.152-3.076-12.5,0.144c-4.109,3.045-35.396,33.651-44.252,38.218c-11.018,5.68-20.66,5.107-32.471,4.453
		c-18.248-1.01-148.351-7.812-165.336-11.17c-7.329-1.448-13.386-1.287-13.106-12.565
		C150.949,237.126,151.782,234.396,151.782,234.396z"/>
					<path fill="#CF6274" d="M385.383,241.893c-1.673,2.24-7.641,8.706-10.587,7.769c-2.656-0.843,2.813-7.28,3.69-9.481
		c1.5-3.759,3.958-6.755,5.006-10.786c1.096-4.219,2.012-8.475-0.211-12.548c-1.152-2.115-2.6-3.978-3.717-6.154
		c-1.096-2.138-1.861-4.401-4.273-5.285c-2.012-0.738-4.041-1.152-6.119-1.924c-2.313-0.858-3.975-1.32-6.417-1.466
		c-2.543-0.151-3.044-1.808-0.868-3.652c1.566-1.327,3.986-1.646,5.814-2.533c3.08-1.494,1.809-2.39,2.813-5.016
		c1.295-3.385,2.227,0.835,4.357,1.082c2.743,0.317,2.141-3.293,3.343-4.75c1.337-1.621,3.72-1.162,5.397-0.163
		c2.238,1.332,2.128,2.961,3.281,4.909c1.234,2.083,3.311,1.809,5.607,2.161c2.679,0.412,3.025-0.726,5.168-1.568
		c2.557-1.004,3.331,0.624,4.736,2.208c2.83,3.193,5.226,4.333,5.99,8.825c0.74,4.344,1.23,8.513,5.868,10.194
		c1.47,0.532,4.125,0.45,4.315,2.532c0.261,2.861-1.345,4.842-2.88,4.754c-4.825-0.279-9.041-2.529-8.979,2.574
		c0.033,2.631,0.16,3.367-2.154,4.909c-1.523,1.016-2.988,1.99-4.525,3.017c-3.141,2.099-1.555,6.927-4.205,9.531
		c-1.408,1.384-8.343,1.018-10.235,1.293"/>
					<path fill="#171414" d="M365.986,203.962c-0.813,1.228-2.465,1.173-3.596,1.924c-0.975,0.648-1.887,1.613-2.721,2.404
		c-1.874,1.78-3.634,3.564-5.159,5.66c-0.722,0.992-1.638,1.837-2.531,2.655c-1.04,0.948-1.784,2.072-2.659,3.167
		c-2.266,2.835-4.742,5.686-6.682,8.76c-2.285,3.626-2.862,7.273-0.721,11.135c0.651,1.174,1.326,2.401,2.246,3.392
		c1.146,1.234,2.814,1.709,4.168,2.661c1.27,0.892,2.275,2.129,3.415,3.164c0.771,0.701,1.59,1.334,2.387,1.992
		c1.062,0.876,1.802,2.029,2.817,2.946c1.367,1.231,3.438,2.445,5.137,3.178c0.736,0.318,1.492,0.421,2.247,0.625
		c0.552,0.149,1.179,0.761,1.722,0.813c1.121,0.107,2.479-2.735,3.037-3.609c0.727-1.138,1.553-2.159,2.352-3.237
		c0.758-1.024,1.271-2.239,2.101-3.229c0.825-0.985,2.011-1.527,2.913-2.442c0.917-0.931,1.783-1.978,2.513-3.073
		c1.497-2.245,3.151-4.28,4.76-6.43c1.462-1.955,3.206-3.732,4.472-5.803c0.693-1.134,1.172-2.257,2.009-3.306
		c0.919-1.152,0.855-2.258,0.699-3.662c-0.163-1.475,0.241-2.366,0.667-3.736c0.501-1.615,0.232-2.374-0.719-3.735
		c-1.237-1.771-2.13-3.443-4.107-4.525c-2.301-1.26-4.889-2.125-7.041-3.576c-0.707-0.477-1.375-1.052-2.154-1.383
		c-1.074-0.457-2.4-0.642-3.544-0.792c-1.481-0.194-3.053,0.083-4.433-0.552c-0.658-0.3-1.134-0.789-1.724-1.165
		c-1.009-0.643-0.743,0.352-1.654,0.5"/>
					<path fill="#8AAEBC" d="M302.427,213.833l-30.551,21.568c0,0-29.586-33.068-30.414-34.465c-0.414-0.708-0.707-1.483-0.879-2.328
		v-0.018c-1.207-5.844,3.172-14.637,10.637-19.086c8-4.758,16.517-5.449,20.259-1.017h0.017l0.052,0.069L302.427,213.833z"/>
					<path fill="#F1F0F2" d="M303.005,214.666c3.285,5.526-2.095,13.994-10.653,19.081c-8.559,5.086-16.829,7.881-20.114,2.357
		c-3.285-5.527,1.212-16.351,9.77-21.438C290.565,209.579,299.72,209.141,303.005,214.666z"/>
					<path fill="#C9C9C9" d="M301.011,216.807c3.065,5.156-1.36,12.7-8.682,17.053c-7.322,4.352-14.47,6.622-17.535,1.469
		c-3.064-5.155,0.506-14.881,7.829-19.234C289.944,211.742,297.946,211.652,301.011,216.807z"/>
					<ellipse fill="#E6E9F2" cx="297.777" cy="252.569" rx="21.229" ry="19.349" />
					<ellipse fill="#D9DCE2" cx="297.453" cy="254.353" rx="18.318" ry="17.013" />
					<ellipse fill="#E6E9F2" cx="297.292" cy="254.28" rx="14.278" ry="12.629" />
					<path fill="#8B7981" d="M197.697,257.356c-0.516-2.474,4.121-6.77,3.467-9.214c-2.632-9.85,8.733-9.348,12.993-11.961
		c4.284-2.627,8.992-5.729,13.685-7.32c2.141-0.727,3.9-2.139,4.741,0.1c0.711,1.896,0.319,4.48,0.208,6.445
		c-0.248,4.384,2.708,7.362,3.097,11.479c0.49,5.207-3.915,5.791-6.336,9.568c-6.343,9.899-7.276,0.429-12.163,0.235
		C213.027,256.516,198.855,262.905,197.697,257.356z"/>
					<path fill="#1E2129" d="M223.17,661.931c0,26.677-21.378,46.869-48.994,46.869c-27.612,0-48.995-20.19-48.995-46.868
		c0-26.673,20.375-48.3,47.988-48.3C200.785,613.632,223.17,635.258,223.17,661.931z"/>
					<ellipse fill="#010003" cx="172.791" cy="664.848" rx="32.531" ry="32.801" />
					<g>
						<path fill="#FFFFFF" d="M368.642,437.008c0.548-0.014,1.013,1.391,1.04,3.14c0.024,1.746-0.397,3.173-0.941,3.183
			c-0.549,0.015-1.013-1.393-1.04-3.139C367.673,438.44,368.095,437.018,368.642,437.008z"/>
						<path fill="#FFFFFF" d="M373.405,452.075l-1.96-3.474l-0.652-3.337c-0.199-1.004-0.549-1.525-0.892-1.413v-0.02l-2.394,0.054
			c-0.502,0.01-0.89,1.319-0.867,2.919l0.174,11.6c0.012,0.729,0.203,1.319,0.434,1.313c0.229-0.007,0.404-0.604,0.393-1.329
			l-0.173-11.604c-0.001-0.143,0.036-0.265,0.08-0.263c0.046,0,0.085,0.116,0.086,0.261l0.397,26.629
			c0.014,0.871,0.246,1.574,0.52,1.57c0.274-0.004,0.482-0.72,0.471-1.593l-0.189-12.652c-0.006-0.298,0.066-0.53,0.159-0.533
			c0.089-0.002,0.168,0.234,0.173,0.525l0.188,12.654c0.014,0.871,0.246,1.574,0.518,1.569c0.275-0.008,0.488-0.724,0.473-1.596
			l-0.397-26.625h0.003c-0.002-0.094,0.009-0.175,0.035-0.226c0.038-0.077,0.088-0.033,0.115,0.091l0.715,3.642
			c0.037,0.192,0.095,0.369,0.158,0.478l2.057,3.648c0.203,0.358,0.451,0.14,0.555-0.496
			C373.689,453.236,373.607,452.434,373.405,452.075z"/>
						<polygon fill="#FFFFFF" points="373.805,469.419 374.249,469.409 374.232,468.266 373.76,468.279 		" />
						<polygon fill="#FFFFFF" points="373.539,462.831 374.152,462.817 374.133,461.674 373.494,461.689 		" />
						<polygon fill="#FFFFFF" points="373.717,467.224 374.215,467.211 374.196,466.068 373.672,466.08 		" />
						<polygon fill="#FFFFFF" points="373.32,466.087 372.795,466.101 372.869,467.242 373.365,467.229 		" />
						<polygon fill="#FFFFFF" points="373.145,461.697 372.51,461.707 372.584,462.85 373.19,462.837 		" />
						<polygon fill="#FFFFFF" points="373.233,463.894 372.65,463.905 372.727,465.046 373.279,465.035 		" />
						<polygon fill="#FFFFFF" points="373.408,468.286 372.938,468.298 373.01,469.434 373.453,469.428 		" />
						<polygon fill="#FFFFFF" points="373.629,465.026 374.182,465.015 374.166,463.873 373.58,463.886 		" />
						<polygon fill="#FFFFFF" points="375.393,467.185 375.891,467.175 375.93,466.031 375.407,466.043 		" />
						<polygon fill="#FFFFFF" points="375.373,469.385 375.811,469.377 375.854,468.232 375.383,468.243 		" />
						<polygon fill="#FFFFFF" points="375.416,464.987 375.967,464.976 376.008,463.834 375.428,463.848 		" />
						<polygon fill="#FFFFFF" points="375.438,462.786 376.046,462.776 376.086,461.632 375.45,461.646 		" />
						<polygon fill="#FFFFFF" points="374.545,467.207 375.043,467.193 375.053,466.05 374.529,466.06 		" />
						<polygon fill="#FFFFFF" points="374.576,469.402 375.021,469.394 375.034,468.251 374.561,468.259 		" />
						<polygon fill="#FFFFFF" points="374.481,462.811 375.088,462.796 375.099,461.654 374.463,461.667 		" />
						<polygon fill="#FFFFFF" points="374.514,465.009 375.064,464.996 375.077,463.854 374.496,463.867 		" />
						<path fill="#FFFFFF" d="M376.413,459.516l-4.292,0.096c-0.184,0.004-0.324,0.477-0.315,1.061c0.007,0.581,0.163,1.048,0.347,1.048
			h0.023l0.826,12.725c0.016,0.246,0.094,0.439,0.171,0.437l2.642-0.054c0.08-0.002,0.148-0.202,0.158-0.45l0.446-12.749
			l0.026-0.003c0.182-0.005,0.32-0.479,0.314-1.06C376.749,459.98,376.597,459.514,376.413,459.516z M372.51,461.707l0.636-0.01
			l0.045,1.139l-0.606,0.014L372.51,461.707z M372.65,463.905l0.583-0.012l0.046,1.142l-0.553,0.011L372.65,463.905z
			 M372.869,467.242l-0.074-1.142l0.525-0.014l0.045,1.143L372.869,467.242z M373.01,469.434l-0.072-1.136l0.471-0.012l0.045,1.142
			L373.01,469.434z M373.082,470.488l0.414-0.007l0.043,1.141l-0.383,0.008L373.082,470.488z M373.299,473.825l-0.076-1.142
			l0.361-0.01l0.045,1.145L373.299,473.825z M374.313,473.8l-0.333,0.009l-0.046-1.139l0.363-0.01L374.313,473.8z M374.279,471.605
			l-0.387,0.011l-0.048-1.144l0.419-0.012L374.279,471.605z M374.249,469.409l-0.444,0.01l-0.045-1.14l0.473-0.014L374.249,469.409z
			 M374.215,467.211l-0.498,0.013l-0.045-1.144l0.524-0.012L374.215,467.211z M374.182,465.015l-0.553,0.012l-0.049-1.141
			l0.586-0.013L374.182,465.015z M374.152,462.817l-0.613,0.014l-0.045-1.142l0.639-0.016L374.152,462.817z M374.977,473.79
			l-0.333,0.005l-0.017-1.142l0.361-0.005L374.977,473.79z M375,471.589l-0.391,0.009l-0.014-1.145l0.414-0.004L375,471.589z
			 M375.021,469.394l-0.445,0.009l-0.016-1.144l0.474-0.008L375.021,469.394z M375.043,467.193l-0.498,0.014l-0.016-1.147
			l0.523-0.01L375.043,467.193z M375.064,464.996l-0.551,0.013l-0.018-1.142l0.581-0.013L375.064,464.996z M375.088,462.796
			l-0.606,0.015l-0.019-1.144l0.636-0.013L375.088,462.796z M375.658,473.773l-0.33,0.008l0.012-1.144l0.357-0.008L375.658,473.773z
			 M375.737,471.574l-0.388,0.007l0.012-1.14l0.415-0.011L375.737,471.574z M375.811,469.377l-0.438,0.008l0.01-1.142l0.471-0.011
			L375.811,469.377z M375.891,467.175l-0.498,0.01l0.015-1.142l0.522-0.01L375.891,467.175z M375.967,464.976l-0.551,0.012
			l0.012-1.14l0.58-0.014L375.967,464.976z M376.046,462.776l-0.608,0.01l0.013-1.141l0.636-0.014L376.046,462.776z"/>
						<polygon fill="#FFFFFF" points="373.721,454.872 374.688,455.673 374.48,458.734 373.509,457.931 		" />
						<path fill="#FFFFFF" d="M372.402,424.188c-6.06-0.227-11.521,14.164-12.203,32.141c-0.679,17.979,3.682,32.736,9.739,32.96
			c6.06,0.229,11.524-14.163,12.204-32.14C382.822,439.171,378.461,424.412,372.402,424.188z M370.051,486.36
			c-5.474-0.205-9.408-13.633-8.791-29.992c0.618-16.36,5.557-29.458,11.029-29.251c5.478,0.204,9.412,13.632,8.795,29.99
			C380.465,473.467,375.523,486.564,370.051,486.36z"/>
					</g>
					<path fill="#EA9F0D" d="M261.75,260.643c2.348-0.949,4.563,2.738,8.123,2.754c1.391,0.006,2.281-0.855,3.707-0.227
		c1.293,0.57,1.729,1.847,2.167,3.082c0.945,2.666,0.808,6.187,2.192,8.55c4.545-4.17,7.425-5.137,13.663-5.345
		c0.357-2.275-1.27-3.762-3.194-4.558c-1.97-0.815-5.477-0.256-3.663-2.527c1.213-1.518,3.878-1.845,4.907-3.46
		c1.641-2.574-1.182-2.951-2.342-4.515c-1.054-1.42-0.295-4.286-2.14-5.241c-2.245-1.163-3.194,1.986-4.946,2.221
		c-3.343,0.45-7.85-6.081-7.505-8.85c0.114-0.91,1.157-2.853,0.392-3.615c-0.863-0.863-3.06,0.147-3.889,0.609
		c-1.012,0.563-1.861,2.042-2.802,2.343c-0.941,0.301-2.402-0.595-3.65,0.028c-2.277,1.139-3.571,3.82-3.677,6.19
		c-0.085,1.904-3.644,9.829-2.442,10.835C256.649,258.918,256.402,262.804,261.75,260.643z"/>
					<path fill="#B0B0B5" d="M301.01,239.804c0,0.677-0.55,1.228-1.228,1.228h-4.873c-0.677,0-1.227-0.55-1.227-1.228l0,0
		c0-0.679,0.55-1.229,1.227-1.229h4.873C300.46,238.575,301.01,239.125,301.01,239.804L301.01,239.804z"/>
					<path fill="#C3CBD7" d="M310.385,228.531c-1.533,2.729,0.811,3.678,1.892,5.604c0.909,1.623,0.708,3.992,0.915,5.817
		c0.438,3.863,4.336,6.31,1.887,10.561c-1.266,2.195-2.076,2.915-2.335,5.544c-0.255,2.582-1.06,5.813-0.646,8.406
		c0.53,3.33,2.075,2.165,4.105,0.866c1.997-1.276,3.903-1.287,6.029-2.049c2.154-0.771,3.527-1.942,5.824-1.076
		c2.211,0.834,2.959,2.753,4.256,4.472c2.482,3.29,8.684,1.812,12.076,0.378c1.752-0.741,3.984-1.209,5.86-1.078
		c1.937,0.136,4.664,2.728,6.519,2.223c1.865-0.508,1.842-3.746,3.617-4.707c2.474-1.338,3.557,0.076,3.711-3.318
		c0.172-3.801,2.02-4.206,5.124-6.052c2.063-1.226,3.636-3.008,5.576-4.462c-1.058-2.172-1.554-2.666,0.243-4.972
		c1.063-1.364,2.951-2.469,3.29-4.258c0.232-1.237-0.663-5.093-1.118-6.334c-0.998-2.707-3.357-1.889-5.621-1.196
		c-1.597,0.488-1.502,0.675-2.811,0.041c-0.877-0.423-1.404-1.259-2.518-1.609c-2.684-0.846-3.846-0.532-5.178-3.233
		c-2.012-4.079,2.813-8.907-1.174-12.278c-1.066-0.901-1.64-0.646-3.034-0.173c-0.559,0.189-1.075,0.649-1.669,0.76
		c-0.469,0.086-1.314-0.172-1.504-0.13c-1.705,0.372-3.289,2.159-4.958,2.396c-5.754,0.822-0.649-10.604-4.144-12.524
		c-1.979-1.089-4.781,1.108-6.68,1.265c-2.012,0.166-4.451-0.572-6.454-0.663c-2.446-0.111-3.235-1.479-3.411-4.082
		c-0.135-1.98-0.398-4.741,0-6.681c0.488-2.367,1.558-2.663-0.48-4.737c-1.853-1.886-2.832-1.453-4.897-1.78
		c-2.479-0.393-4.96-1.5-7.5-1.896c-2.464-0.384-4.683-1.325-4.384,1.917c-1.502-0.325-4.336-0.855-4.568,1.537
		c-0.209,2.123,3.277,2.297,3.521,4.048c0.22,1.572-3.12,1.323-4.584,1.34c-2.2,0.026-4.519,0.38-6.67,0.237
		c0.041,0.964,0.667,1.747,0.683,2.78c0.013,0.936-0.503,1.879-0.634,2.802c-0.514,3.623,1.984,1.715,3.272,3.828
		c0.945,1.55-1.698,2.885-2.369,4.357c-1.173,2.576,0.654,2.743,2.365,4.046c1.946,1.48,2.003,2.555,2.102,4.793
		c0.092,2.085,0.774,3.273-0.215,5.388c-1.067,2.281-2.004,3.034-0.21,5.173c1.798,2.143,2.149,1.331,4.525,1.071
		c0.234-0.025,0.706-0.213,0.905-0.236c-0.107,0.203-0.266,0.355-0.473,0.458"/>
					<polygon fill="#24A7E9" points="240.584,198.591 240.584,198.609 240.566,198.591 	" />

					<ellipse transform="matrix(0.8351 0.55 -0.55 0.8351 179.1968 -173.0988)" fill="#F1F0F5" cx="378.367" cy="212.392" rx="15.066" ry="9.802" />

					<ellipse transform="matrix(0.8351 0.5501 -0.5501 0.8351 179.2122 -173.128)" fill="#8D8689" cx="378.385" cy="212.363" rx="13.478" ry="8.397" />
					<path fill="#171414" d="M375.039,210.966c-1.375,1.005-4.416,0.084-4.64,2.449c-0.183,1.937,2.378,2.912,3.644,3.825
		c1.35,0.973,3.036,2.086,4.659,2.451c1.012,0.227,1.861,0.141,2.454-0.858c0.673-1.135-1.891-3.833-1.268-5.388
		c0.322-0.808-1.321,2.155-3.234,1.078C374.392,213.247,375.039,210.966,375.039,210.966z"/>
				</g>
			</svg>

		</div>
	)
}



export default Waste