import React from 'react'

import StoAppHeader from './Animations/StoAppHeader';
import StoAppFirstSection from './Animations/StoAppFirstSection';
import StoAppProblem from './Animations/StoAppProblem';
import StoAppFooter from './Animations/StoAppFooter';
import StoAppGetApp from './Animations/StoAppGetApp';

const StorageApp = () => {


    return (
        <div className="stoapp-container">

            <StoAppHeader />
            <StoAppProblem/>
            <StoAppFirstSection/>
            <StoAppGetApp/>
            <StoAppFooter/>

        </div>
    )
}

export default StorageApp