import React, { useEffect } from 'react'

import gsap from 'gsap/gsap-core';
import { ScrollTrigger } from "gsap/ScrollTrigger"

const StoAppHeader = () => {


    useEffect(() => {

        // Arrow Animation

        gsap.to('.arrow', {
            y: -5,
            repeat: -1,
            yoyo: true,
            ease: 'linear'
        })


        // HEADER ANIMATIONS

        const headerTimeLine = gsap.timeline({
            id: "headerID",
            scrollTrigger: {
                trigger: ".stoAppHeader",
                start: "top 0%",
                end: "bottom 30%",
                // toggleActions: 'play none none reverse',
                pin: ".introText",
                scrub: .2,
                // markers: true
            }
        });

        headerTimeLine
            .to(".arrow", { duration: .2, opacity: 0 })
            .to(".line2", { y: 10, duration: .2, opacity: 0, ease: 'back.inOut(2)' }, '-=.1')
            .to(".slogan", { duration: .5, opacity: 0 })


        return () => {
            ScrollTrigger.getById("headerID").kill();
        }

    }, []);



    return (
        <div className="stoAppHeader">
            <div className="introText">
                <h1 className="slogan title">Storage App</h1>
                <h3 className="bold spread line2">Personal storage system</h3>
                {/* <p className="line3">Created 2021 with 🖤 by Dennis Schoene & Markus Klein</p> */}
                <p className="arrow">down</p>
            </div>

            <div className="img-wrapper">
                <img src="stoAppHeader.webp" alt="" />
            </div>
        </div>
    )
}

export default StoAppHeader