import React, { useEffect } from 'react'

import gsap from 'gsap/gsap-core';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Waste from 'components/Utils/Viz/Waste';

const StoAppProblem = () => {


    useEffect(() => {


        // SPRITE ANIMATION

        var spriteSheet = {
            width: 1080,
            height: 1080,
            total: 15,
            cols: 4,
            rows: 4,
            duration: .5
        };

        var spriteTimeline = gsap.timeline({
            id: "spriteID",
            scrollTrigger: {
                trigger: ".stoAppProblemPara3",
                // scrub: 0.15,
                toggleActions: 'play none none reverse',
                start: "top top",
                end: "bottom 20%",
                // markers: true
            }
        });


        for (var i = 0; i < spriteSheet.total; i++) {
            spriteTimeline.set(".frames", {
                x: (i % spriteSheet.cols) * -spriteSheet.width,
                y: Math.floor(i / spriteSheet.cols) * -spriteSheet.height
            }, i / (spriteSheet.total - 1) * spriteSheet.duration);
        }


        // CONTAINER PINNING

        gsap.timeline({
            id: "problemID",
            scrollTrigger: {
                trigger: ".stoAppProblem",
                start: "top 0%",
                end: "bottom 10%",
                toggleActions: 'play none none reverse',
                pin: ".stoAppProblem",
                // scrub: 2,
                // markers: true
            }
        });

        // ANIMATION TIMELINE 



        const stoAppTimeLine = gsap.timeline({
            id: "problemID",
            scrollTrigger: {
                trigger: ".stoAppProblem",
                start: "top 55%",
                end: "bottom 10%",
                toggleActions: 'play none none reverse',
                scrub: 1,
                onUpdate: self => {
                    // do something with progress like update a video or canvas
                }
                // markers: true
            }
        });




        //////////////////////////////////////
        // RISE 

        const riseTimeLine = gsap.timeline({
            id: "riseID",
            scrollTrigger: {
                trigger: ".stoAppProblem",
                start: "bottom 90%",
                end: "bottom 80%",
                toggleActions: 'play none none reverse',
                // markers: true,
            }
        });


        riseTimeLine
            .from(".rise", { x: 20, duration: .5, opacity: 0, ease: 'back.inOut(1)' },)
            .to(".stoAppImage", { x: -10, duration: .2 }, '-=.4')
            .from(".stoAppProblemIcon", { duration: .3, x: -10, opacity: 0, ease: 'back.inOut(1)' }, '-=.6')






        stoAppTimeLine
            .from(".stoAppProblemHeader", { x: -20, duration: .2, opacity: 0, ease: 'back.inOut(1)' },)
            .from(".stoAppProblemPara", { x: -20, duration: .2, opacity: 0, ease: 'back.inOut(1)' },)
            .from(".waste-wrapper", { y: 20, duration: .5, opacity: 0, ease: 'back.inOut(1)' }, '+=.5')
            .to(".waste-wrapper", { duration: .5, opacity: 0.2, ease: 'back.inOut(1)' },)
            .from(".stoAppProblemPara2", { x: 20, duration: .5, opacity: 0, ease: 'back.inOut(1)' },)
            .from(".stoAppImage", { duration: .5, opacity: 0, ease: 'back.inOut(1)' },)


        return () => {
            ScrollTrigger.getById("problemID").kill();
            ScrollTrigger.getById("spriteID").kill();
            ScrollTrigger.getById("riseID").kill();
        }

    }, []);



    return (
        <div className="stoAppProblem">

            <div className="stoAppProblemSpacer"></div>

            <div className="stoAppProblemHeader">
                <h3>
                    <span>Food waste </span>
                    <span className="hit">rising </span>
                    <span>amid coronavirus pandamic</span>
                </h3>
            </div>

            <div className="backdrop">
                <div className="stoAppProblemPara">
                    <p className="tiny padding">One of the biggest causes of food waste are households where vegetables wilt, milk spoils and leftovers lie dormant out of sight in the darkest corner refrigerator until they are thrown away.</p>
                    <h4 style={{ fontStyle: 'normal' }}>People are cooking more at home</h4>
                    <p className="tiny padding">
                        <span>Due to restrictions on the public and the partial closure of restaurants, more and more food is being cooked at home. This change also means that often too much is bought, which due to </span>
                        <span className="bold">poor planning</span>
                        <span>, ends up in </span>
                        <span className="bold">oblivion</span>
                        <span> and finally in the </span>
                        <span className="bold">garbage</span>
                    </p>
                </div>

                <div className="ProblemWrapper">
                    <div className="stoAppProblemPara2">
                        <h3>Manage items differently - in a simple way</h3>
                        <p className="tiny padding bulletpoint">Straightforward system for keeping track of shelf lives</p>
                        <p className="tiny padding bulletpoint">Alertsystem for soon expiring items and expired items</p>
                        <p className="tiny padding bulletpoint">Shared warehouses to always keep track</p>
                    </div>
                </div>



                <div className="stoAppImage">
                    <img src="ortho.webp" alt="" />
                </div>

                <Waste />

                <div className="stoAppProblemIcon">
                    <svg viewBox="0 0 90 112.5"><path fill="#db7070" d="M71.5147476,62.0395012c3.1660004-5.039402,5.0033035-10.9976997,5.0033035-17.3883018  c0-18.0826988-14.6589012-32.7416-32.7415009-32.7416c-18.0827007,0-32.7416,14.6589003-32.7416,32.7416  s14.6588993,32.7416,32.7416,32.7416c6.9028015,0,13.3030014-2.1413956,18.5831985-5.7888031l16.6053009,6.4864044  L71.5147476,62.0395012z M48.7514496,65.9515991c0,0.4964981-0.4062004,0.9027023-0.9026985,0.9027023h-8.1445007  c-0.4963989,0-0.9025993-0.4062042-0.9025993-0.9027023v-8.1445999c0-0.4963989,0.4062004-0.9025993,0.9025993-0.9025993h8.1445007  c0.4964981,0,0.9026985,0.4062004,0.9026985,0.9025993V65.9515991z M48.7514496,51.7317009  c0,0.4071999-0.3331985,0.7403984-0.7405014,0.7403984h-8.4688988c-0.4071999,0-0.7403984-0.3331985-0.7403984-0.7403984V23.8859997  c0-0.4071999,0.3331985-0.7404003,0.7403984-0.7404003h8.4688988c0.4073029,0,0.7405014,0.3332005,0.7405014,0.7404003V51.7317009z" /></svg>
                </div>

                <div className="rise">
                    <h2 >
                        <span style={{ color: 'white' }}>A better storage system with </span>
                        <span className="hit" style={{ color: 'white' }}>Storage App</span>
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default StoAppProblem