import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from 'gsap';



const Hamburger = ({ state }) => {

    // Vars to get access to the Dom-Elements
    let menu = useRef(null)
    let revealMenu = useRef(null)
    let revealMenuBackground = useRef(null)
    let line1 = useRef(null)
    let line2 = useRef(null)
    let line3 = useRef(null)
    let line4 = useRef(null)
    let line5 = useRef(null)
    let info = useRef(null)


    useEffect(() => {
        if (state.clicked === false) {
            // close the menu
            gsap.to([revealMenu, revealMenuBackground], {
                duration: .8,
                height: 0,
                ease: 'power3.inOut',
                stagger: {
                    amount: 0.07
                }
            })
            gsap.to(menu, {
                duration: 1,
                css: {
                    display: 'none'
                }
            })
        } else if (state.clicked === true || (state.clicked === true && state.initial === null)) {
            // open our menu
            // the pipe operator is for the initial call
            gsap.to(menu, {
                duration: 0,
                css: {
                    display: 'block'
                }
            })
            gsap.to([revealMenuBackground, revealMenu], {
                duration: 0,
                opacity: 1,
                height: "100%",
            });
            staggerReavel(revealMenuBackground, revealMenu)
            fadeInUp(info, 0)
            staggerText(line1, line2, line3, line4, line5)
        }
    }, [state])



    const staggerReavel = (node1, node2) => {
        gsap.from([node1, node2], {
            duration: .8,
            // height: 0,
            transform: 'translateY(-100vh)',
            transformOrigin: 'right bottom',
            // skewY: 5,
            ease: 'power3.inOut',
            stagger: {
                amount: 0.02
            }
        })
    }


    const fadeInUp = (node, delay) => {
        gsap.from(node, {
            y: 60,
            duration: 1,
            delay: delay,
            opacity: 0,
            ease: 'power3.inOut'
        });
    }


    const staggerText = (node1, node2, node3, node4, node5) => {
        gsap.from([node1, node2, node3, node4, node5], {
            duration: .8,
            y: 50,
            delay: .2,
            ease: 'power3.inOut',
            stagger: {
                amount: .3
            }
        })
    }



    const handleHover = (e) => {
        gsap.to(e.target, {
            duration: .3,
            y: 5,
            skewX: 4,
            // ease: 'power3.inOut'
        })
    }


    const handleHoverExit = (e) => {
        gsap.to(e.target, {
            duration: .3,
            y: -5,
            skewX: 0,
            ease: 'power3.inOut'
        })
    }


    return <div ref={el => (menu = el)} className='hamburger-menu'>
        <div ref={el => (revealMenuBackground = el)} className="menu-secondary-background-color"></div>
        <div ref={el => (revealMenu = el)} className="menu-layer">
            <div className="wrapper">


                <div className="menu-common">
                    <nav>
                        <ul>
                            <h1>Personal</h1>
                            <li  ref={el => (line1 = el)}>
                                <Link
                                    onMouseEnter={e => handleHover(e)}
                                    onMouseOut={e => handleHoverExit(e)}
                                    to='/'>Home</Link>
                            </li>
                            <li ref={el => (line2 = el)}>
                                <Link
                                    style={{ opacity: .2, cursor: 'not-allowed' }}
                                    onMouseEnter={e => handleHover(e)}
                                    onMouseOut={e => handleHoverExit(e)}
                                    to='/'
                                >
                                    <span>Journey</span>
                                </Link>
                                <p></p>
                                <p>What I did, what I learned, what I tried to learn, what I am proud of, my strengths and the positives about this</p>
                            </li>
                            <li ref={el => (line3 = el)}>
                                <Link
                                    onMouseEnter={e => handleHover(e)}
                                    onMouseOut={e => handleHoverExit(e)}
                                    to='/contact'>Contact</Link>
                                <p>I'm always keen to hear from you and there are plenty of ways to get in touch</p>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div ref={el => (info = el)} className="menu-projects">
                    <nav>
                        <ul>
                            <h1>Projects</h1>
                            <li ref={el => (line4 = el)} >
                                <Link
                                    onMouseEnter={e => handleHover(e)}
                                    onMouseOut={e => handleHoverExit(e)}
                                    to='/storageapp'>Storage App</Link>
                                <p>Personal storage system to reduce food waste</p>
                            </li>
                            <li ref={el => (line5 = el)}>
                                <Link
                                    onMouseEnter={e => handleHover(e)}
                                    onMouseOut={e => handleHoverExit(e)}
                                    to='/algae'>Algae Monitor</Link>
                                <p>ML injected algae cultivation</p>
                            </li>

                        </ul>
                    </nav>
                </div>



            </div>

            <div className="footer">

            </div>

        </div>
    </div>
};

export default Hamburger;