import React from 'react'

const Upcoming = () => {

    return (

        <svg viewBox="0 0 100 125">
            <g>
                <path fill="#222222" d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" />
                <path fill="#222222" d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z" />
                <path fill="#222222" d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" />
                <path fill="#222222" d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" />
            </g>
            <g>
                <path fill="#222222" d="M22.3,22.3c0.8,0.8,2,0.8,2.8,0C38.9,10,61.2,10.1,75,22.4l-5.6,5.6c-1.1,1.1-0.6,3,1,3.4l23.3,5.6     c1.5,0.4,2.8-1,2.5-2.5l-5.6-23.3c-0.4-1.5-2.3-2.1-3.4-1l-4.8,4.8C73.4,7.1,62,2.6,50,2.6C38,2.6,26.8,7,18,15     c-0.8,0.8-0.8,2.1,0,2.9L22.3,22.3z" />
                <path fill="#222222" d="M46.9,22.9c-1.1,0-2,0.9-2,2v22c0,0.2-0.1,0.3-0.2,0.4l-15,9.4c-1,0.6-1.2,1.9-0.6,2.8l3.2,5.1c0.6,1,1.9,1.2,2.8,0.6     l19.1-11.9c0.6-0.4,1-1,1-1.7V24.9c0-1.1-0.9-2-2-2H46.9z" />
                <path fill="#222222" d="M13.3,57l-0.6-8.1c-0.1-1.1-1-1.9-2.1-1.9l-6.1,0.3c-1.1,0.1-2,1-1.9,2.1l0.2,3c0,0.8,0.1,1.7,0.2,2.5l0.3,3     c0.1,1.1,1.1,1.9,2.2,1.8l6.1-0.6C12.6,59,13.4,58.1,13.3,57z" />
                <path fill="#222222" d="M6.1,37.5l5.7,2.3c1,0.4,2.2-0.1,2.6-1.1l3.3-7.5c0.4-1,0-2.2-1-2.7L11.2,26c-1-0.5-2.2,0-2.7,1l-1.3,2.6     c-0.4,0.8-0.7,1.6-1.1,2.4L5,34.8C4.5,35.9,5,37.1,6.1,37.5z" />
                <path fill="#222222" d="M16.6,66.6c-0.6-0.9-1.8-1.2-2.8-0.6l-5.2,3.2c-1,0.6-1.3,1.8-0.7,2.8l1.6,2.6c0.5,0.7,0.9,1.4,1.4,2.2l1.7,2.5     c0.6,0.9,1.9,1.2,2.8,0.5l5.1-3.4c0.9-0.6,1.2-1.9,0.6-2.8L16.6,66.6z" />
                <path fill="#222222" d="M35.5,84.4l-7.1-4c-1-0.5-2.2-0.2-2.7,0.7l-3.1,5.3c-0.6,1-0.3,2.2,0.7,2.8l2.7,1.6c0.7,0.4,1.4,0.8,2.2,1.2l2.7,1.4     c1,0.5,2.2,0.1,2.7-0.9l2.8-5.4C36.8,86.1,36.5,84.9,35.5,84.4z" />
                <path fill="#222222" d="M53.2,87.2l-7.8-0.2c-1.1,0-2,0.8-2.1,2L43,95.1c0,1.1,0.8,2.1,2,2.1l5.6,0.2l2.9,0c1.2,0,2.1-1,2-2.2l-0.3-6     C55.1,88.1,54.2,87.3,53.2,87.2z" />
                <path fill="#222222" d="M90.9,70.4L85.7,67c-0.9-0.6-2.2-0.4-2.8,0.6l-4.6,6.7c-0.6,0.9-0.4,2.2,0.5,2.8l5,3.6c0.9,0.7,2.2,0.5,2.8-0.5l1.8-2.5     c0.5-0.7,1-1.4,1.4-2.1l1.6-2.5C92.1,72.2,91.8,71,90.9,70.4z" />
                <path fill="#222222" d="M95.6,48.6l-6.1-0.5c-1.1-0.1-2.1,0.7-2.2,1.8L86.5,58c-0.1,1.1,0.7,2.1,1.8,2.2l6.1,0.8c1.1,0.1,2.1-0.6,2.3-1.8l0.4-3     c0.1-0.8,0.2-1.7,0.3-2.5l0.2-3C97.6,49.7,96.7,48.7,95.6,48.6z" />
                <path fill="#222222" d="M73.4,81.9c-0.5-1-1.7-1.3-2.7-0.8l-7.3,3.8c-1,0.5-1.4,1.7-0.9,2.7l2.7,5.5c0.5,1,1.7,1.4,2.7,0.9l2.7-1.3     c0.7-0.4,1.5-0.7,2.2-1.2l2.7-1.5c1-0.5,1.3-1.8,0.8-2.8L73.4,81.9z" />
            </g>
        </svg>

    )
}

export default Upcoming