import React, { useEffect } from 'react'

import gsap from 'gsap/gsap-core';
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Construction from './Utils/Viz/Construction';
import { Link } from 'react-router-dom';

const Algae = () => {


    useEffect(() => {


        // Arrow Animation

        gsap.to('.arrow', {
            y: -5,
            repeat: -1,
            yoyo: true,
            ease: 'linear'
        })


        // DESCRIPTION ANIMATIONS

        let targets = document.querySelectorAll(".description p")

        const descriptionTimeLine = gsap.timeline({
            id: "descriptionID",
            scrollTrigger: {
                trigger: ".description",
                start: "top 70%",
                end: "bottom bottom",
                toggleActions: 'play none none reverse',
                // scrub: 1,
                // markers: true
            }
        });

        descriptionTimeLine
            .from(targets, { y: -10, opacity: 0, stagger: .2, duration: .2 },)
            .from('.descriptionHeader', { y: -5, opacity: 0, duration: .2 }, '-=1')
            .from('.constructionWrapper', { x: -10, opacity: 0, duration: .2 },)
            .from('.footer', { y: 20, opacity: 0, duration: .2 },)




        // KILL ALL SCROLLTRIGGERS    

        return () => {
            ScrollTrigger.getById("descriptionID").kill();
        }

    }, []);



    return (
        <div className="algaeContainer">
            <div className="hero">
                <div className="textWrapper">
                    <h1 className="title">Algae Monitor</h1>
                    <p className="bold spread">ML injected algae cultivation</p>
                    <p className="arrow tiny padding">down</p>
                </div>

            </div>

            <div className="algaeWrapper">
                <img src="algae.webp" className="algaeImage" alt="" />
            </div>

            <svg viewBox="0 0 1240 320" className="spacer">
                <path fill="#f0f0f0" fillOpacity="1" d="M0,64L120,53.3C240,43,480,21,720,16C960,11,1200,21,1320,26.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
            </svg>

            <div className="description">
                <div className="descriptionFirst">
                    <p className="padding tiny">
                        The production of food for a growing world population is a major challenge.
                        In particular, protein and the long-chain n-3 fatty acids, eicosapentaenoic acid (EPA), and docosahexaenoic acid (DHA), which exert a series of potential health effects, are scarce resources in the context of global food security.
                        Fish from wild capture and aquaculture production cannot meet the current demand for EPA and DHA. Therefore, a supplementation with alternative sources is crucial.
                        </p>
                </div>

                <div className="descriptionSecond">
                    <h3 className="descriptionHeader spread">
                        <span>:: </span>
                        <span className='hit'>Primary Objectives</span>
                    </h3>
                    <p className="padding   tiny spread">Maximization of net yield</p>
                    <p className="   tiny spread">Reduction downstream processing costs</p>
                    <p className="padding   tiny spread">Optimization of the cultivation process by early detection of limitations or inhibitions</p>
                    <p className="   tiny spread">Reduction of contamination, pests and diseases</p>
                    <div className="constructionWrapper">
                        <Construction />
                    </div>
                </div>
            </div>


            <div className="footer">
                <h4>Currently in development</h4>
                <p className="padding tiny">
                    <span>Stay tuned - Interested? Please </span>
                    <span className="bold">
                        <Link
                            to='/contact'>contact me</Link>
                    </span>
                </p>
            </div>

        </div>
    )
}

export default Algae