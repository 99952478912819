import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap/gsap-core';
import Android from 'components/Utils/Viz/Android';

const StoAppGetApp = () => {

    const [status, setStatus] = useState(false)

    const downloadRef = useRef(null);
    const downloadWrapperRef = useRef(null);

    useEffect(() => {
        if (status) {
            gsap.to(downloadWrapperRef.current, {
                duration: .4,
                opacity: 1,
                x: -20,
                ease: 'back.inOut(3)',
            });
            gsap.to(downloadRef.current, {
                duration: .4,
                opacity: 1,
                x: 50,
                ease: 'back.inOut(2)'
            });
        } else {
            gsap.to(downloadWrapperRef.current, {
                duration: .4,
                opacity: 1,
                x: 0,
                ease: 'back.inOut(3)',
            });
            gsap.to(downloadRef.current, {
                duration: .4,
                opacity: 1,
                x: 0,
                ease: 'back.inOut(2)'
            });
        }
    }, [status])




    return (
        <div className="stoAppGetApp">
            <h2 className="spread padding">Download::</h2>
                <p className="tiny">Since the application is work in progress, no warranty can be given. Downloading is allowed only with consent. (Developer Mode: Size ~ 50mb)</p>
            <p className="tiny padding">
                <span>Still ready to </span>
                <span className="hit">try?</span>
                <span> (Currently only for Android)</span>

            </p>
            <div className="androidWrapper">
                <Android />
            </div>
            <div className="downloadWrapper" onClick={() => setStatus(!status)} ref={downloadWrapperRef}>
                {/* <p className="tiny padding hit" ref={downloadRef}>Get it now</p> */}
                <a className="tiny padding hit" href="files/StorageApp.apk" download="StorageApp.apk">Get it now</a>
            </div>





        </div>
    )
}

export default StoAppGetApp